(function () {
    document.getElementById("preloader").style.display = "block";
})();

(function () {
    var preloader = document.getElementById("preloader");
    preloader.classList.add("is-out");
    setTimeout(function () {
        preloader.style.display = "none";
    }, 260);
})();

function throttle(func, ms) {
    var isThrottled = false,
        savedArgs,
        savedThis;

    function wrapper() {
        if (isThrottled) {
            savedArgs = arguments;
            savedThis = this;
            return;
        }

        func.apply(this, arguments);

        isThrottled = true;

        setTimeout(function () {
            isThrottled = false;
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}

(function () {
    var requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame;
    window.requestAnimationFrame = requestAnimationFrame;
})();

function lockHtml() {
    var scrollWidth = window.innerWidth - $("html").width();

    $("body").css("margin-right", scrollWidth + "px");
    $("html").addClass("is-lock");
}
function unLockHtml() {
    $("body").css("margin-right", 0);
    $("html").removeClass("is-lock");
}

$(document).on("change", "[data-editable-checkbox]", function () {
    var parent = $(this).parent(),
        checkbox = $(this);
    if (!this.checked) {
        var field = parent.find("[data-editable-field]"),
            label = parent.find("[data-editable-label]");

        parent.addClass("is-edit");

        field.focus();
        field.on("focusout", function () {
            if ($(this).val() == "") {
                label.html("0");
            } else {
                label.html($(this).val());
            }

            checkbox[0].checked = true;
            parent.removeClass("is-edit");
        });
    }
});

(function () {
    //Filled class for inputs
    $("body")
        .on("keydown", "[data-input-placeholder]", function () {
            $(this).addClass("is-filled");
        })
        .on("focusout", "[data-input-placeholder]", function () {
            if ($(this).val() == "") {
                $(this).removeClass("is-filled");
            }
        });

    $("[data-input-placeholder]").each(function () {
        if (!$(this).val() == "") {
            $(this).addClass("is-filled");
        }
    });
})();

lazyloadItems("[data-lazyload-after]");

(function () {
    var cookieConfirm = document.querySelector("[data-cookie-confirm]"),
        isLast = false;

    if (!cookieConfirm) {
        return;
    }

    var cityConfirmBlock = document.getElementById("dropCityPicker");
    cookieConfirm.addEventListener("click", function (e) {
        closeCookieContainer(e);
        const date = new Date();
        cookie.set("COOKIES_USAGE_ACCEPTED", date.toLocaleString(), {
            expires: 365,
            path: "/",
        });

        var cityConfirmBlockOverlay = cityConfirmBlock.querySelector(
            ".js-city-picker-overlay"
        );
        var cityConfirmBlockCloseBtn = cityConfirmBlock.querySelector(
            ".js-city-popup-close"
        );
        if (cityConfirmBlockOverlay)
            cityConfirmBlockOverlay.classList.add("is-mobile");
        if (cityConfirmBlockCloseBtn)
            cityConfirmBlockCloseBtn.style.display = "inline-flex";

        setTimeout(function () {
            cityConfirmBlock.classList.remove("is-open");
        }, 100);
    });

    function closeCookieContainer(event) {
        if (isLast) {
            return;
        }
        isLast = true;

        event.stopPropagation();

        var cookieContainer = document.querySelector("[data-cookie-container]");
        if (!cookieContainer) {
            return;
        }

        cookieContainer.style.display = "none";
        cookieConfirm.removeEventListener("click", closeCookieContainer);
    }
})();

(function () {
    var container = document.getElementById("dropCityPicker");
    if (!container) {
        return;
    }

    var cityConfirmBlock = document.getElementById("dropCityPicker");
    var cityConfirmBlockOverlay = cityConfirmBlock.querySelector(
        ".js-city-picker-overlay"
    );
    var cityConfirmBlockCloseBtn = cityConfirmBlock.querySelector(
        ".js-city-popup-close"
    );

    function init() {
        setTimeout(function () {
            showConfirm();
        }, 1800);
    }

    // проверка, выбирал ли пользователь город
    function showConfirm() {
        if (cookie.get("COOKIES_USAGE_ACCEPTED")) {
            cityConfirmBlock.classList.remove("is-open");
            if (cityConfirmBlockOverlay)
                cityConfirmBlockOverlay.classList.add("is-mobile");
            if (cityConfirmBlockCloseBtn)
                cityConfirmBlockCloseBtn.style.display = "inline-flex";
        } else {
            cityConfirmBlock.classList.add("is-open");
            if (cityConfirmBlockCloseBtn)
                cityConfirmBlockCloseBtn.style.display = "none";
        }
    }
    init();
})();

(function () {
    var container = document.getElementById("dropCityPickerConnection");
    if (!container) {
        return;
    }

    var cityPicker = container.querySelector(".selectpicker");

    $(".selectpicker", container).on("hide.bs.select", function () {
        window.closeModals();
    });

    cityPicker.addEventListener("change", function (e) {
        window.location.reload();
    });
})();

(function () {
    var checkedValues = [];

    const dropCheckboxes = document.querySelectorAll("[data-drop-checkbox]");
    let countValues = 0;
    [].forEach.call(dropCheckboxes, function (el) {
        el.addEventListener("change", function () {
            var checkboxValue = el.getAttribute("data-drop-checkbox"),
                dropInputValue = el
                    .closest("[data-drop-input]")
                    .querySelector("[data-drop-input-value]"),
                dropCloseInner = el
                    .closest("[data-drop-input]")
                    .querySelector(".js-drop-close"),
                totalValue = "";

            if (el.checked) {
                checkedValues[checkboxValue] = checkboxValue;
                countValues++;
            } else {
                checkedValues[checkboxValue] = "";
                countValues--;
            }

            for (var key in checkedValues) {
                if (checkedValues[key] !== "") {
                    totalValue += checkedValues[key] + " ";
                }
            }
            dropInputValue.innerHTML = totalValue;

            if (countValues > 1) {
                dropCloseInner.classList.add("is-show");
            } else {
                dropCloseInner.classList.remove("is-show");
            }
        });
    });
})();

$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();

    $(".js-form-payment").on("click", function (event) {
        var self = $(this);
        if (
            self.hasClass("js-form-payment-with-disable") &&
            !self.hasClass("is-disable")
        )
            return;
        event.preventDefault();

        self.addClass("is-overlay");
        $(".js-form-payment-refresh").show();
        $(".js-form-payment-download").hide();
        $(".js-form-payment-doc", $(this)).addClass("is-hide");
        $(".js-form-payment-doc-form").addClass("is-hide");
        $(".js-form-payment-refresh").removeClass("is-disable");

        setTimeout(function () {
            self.removeClass("is-overlay");
            if (self.hasClass("js-hide")) {
                self.hide();
            }
            if (self.hasClass("is-doc")) {
                self.removeClass("is-disable");
                self.removeClass("js-form-payment");
                self.off("click");
            }
            $(".js-doc-download").addClass("fadeIn").show();
            $("[data-catalog-view]").addClass("fadeIn").show();
            $("[data-catalog-list]").addClass("fadeIn").show();
            $(".js-form-btns-toggle").addClass("is-show");
            $(".js-form-payment-download").show();
            $(".js-form-payment-doc").removeClass("is-hide");
            $(".js-form-payment-doc-form").removeClass("is-hide");
            if ($(".js-form-payment-download").length > 0)
                $(".js-form-payment-refresh").hide();
        }, 1500);
    });

    function initCalendars(container = document) {
        $(".js-period-picker", container).datepicker({
            view: "months",
            minView: "months",
            dateFormat: "MM yyyy",
            autoClose: true,
            position: "bottom left",
        });

        $(".js-period-picker-right", container).datepicker({
            view: "months",
            minView: "months",
            dateFormat: "MM yyyy",
            autoClose: true,
            position: "bottom right",
        });

        $(".js-period-picker-days", container).datepicker({
            view: "days",
            minView: "days",
            dateFormat: "dd.mm.yyyy",
            autoClose: true,
            position: "bottom left",
        });
        $(".js-period-picker-days-year", container).datepicker({
            view: "years",
            minView: "days",
            maxView: "years",
            dateFormat: "dd.mm.yyyy",
            autoClose: true,
            position: "bottom left",
        });
    }

    initCalendars();

    window.initCalendars = initCalendars;

    // Маска для телефона
    $('[type="tel"]').mask("Z0#", {
        translation: {
            Z: {
                pattern: /[\+]/,
                optional: true,
            },
        },
    });
    $("[data-mask-phone]").mask("+7 900-000-0000");
    $("[data-mask-series]").mask("0000");
    $("[data-mask-max-8]").mask("000000000", {
        translation: { 0: { pattern: /[0-9*.,]/ } },
    });
    $("[data-mask-passport-number]").mask("000000");
    $('[type="mail"]').mask("A", {
        translation: {
            A: { pattern: /[\w@\-.+]/, recursive: true },
        },
    });
    $("[data-mask-positive-number]").mask("A", {
        translation: {
            A: { pattern: /[\d.,]/, recursive: true },
        },
    });
    $("[data-mask-snils]").mask("000-000-000 00");
    $("[data-mask-date]").mask("00.00.0000");
    $("[data-mask-info-number]").mask("0#");
    $("[data-mask-number]").mask("000,000000");
    $("body").on("input", '[data-type="text"]', function () {
        this.value = this.value.replace(/[^а-яё\s\-]/gi, "");
    });
    var modalAnimationTime = 0;
    $("body").on("click", "[data-drop-toggle]", function () {
        toggleDrop(this);

        $(".bootstrap-select.open").removeClass("open");

        if ($(this).attr("data-drop-toggle") == "dropCityPicker") {
            var cookieContainer = document.querySelector(
                "[data-cookie-container]"
            );
            var cityContainer = document.querySelector("[data-city-container]");

            if (cookieContainer || cityContainer) {
                cookieContainer.style.display = "none";
                cityContainer.style.display = "block";
            }

            if (cityContainer) {
                if (this.classList.contains("is-open")) {
                    $("#dropCityPicker .bootstrap-select").addClass("open");
                } else {
                    $("#dropCityPicker .bootstrap-select").removeClass("open");
                }
            }
        }

        if ($(this).attr("data-drop-toggle") == "dropMap") {
            myMap.container.fitToViewport();
        }

        if ($(this).attr("data-drop-toggle") == "headerSearch") {
            if ($("#headerSearch").hasClass("is-open")) {
                $("#headerSearch").find("input").focus();
            } else {
                $("#headerSearch").find("input").blur();
            }
        }
        return false;
    });

    function closeModals() {
        $("[data-drop-toggle]").each(function () {
            var targetSelector = $(this)
                    .removeClass("is-open")
                    .attr("data-drop-toggle"),
                target = $("#" + targetSelector);

            if (target.hasClass("is-open")) {
                target.addClass("is-out");

                setTimeout(
                    (function (item) {
                        return function () {
                            item.removeClass("is-out is-open");
                            unLockHtml();
                        };
                    })(target),
                    modalAnimationTime
                );
            }
        });
    }

    window.closeModals = closeModals;
    $(document).on("keydown", function (event) {
        if (event.keyCode == 27) {
            closeModals();
        }
    });

    function toggleDrop(_this) {
        var $this = $(_this),
            target = $this.attr("data-drop-toggle"),
            $target = $("#" + target);

        $("#pageHeader").removeClass("is-inverse");

        if (!$target.hasClass("is-open")) {
            if (!$this[0].hasAttribute("data-not-close-others")) {
                closeModals();
            }
            $('[data-drop-toggle="' + target + '"]').toggleClass("is-open");
            if (!$this[0].hasAttribute("data-scroll-free")) {
                lockHtml();
            }
        }
        if ($target.hasClass("is-open")) {
            $target.addClass("is-out");

            $('[data-drop-toggle="' + target + '"]').removeClass("is-open");

            setTimeout(
                (function (item) {
                    return function () {
                        unLockHtml();
                        item.removeClass("is-out is-open");
                    };
                })($target),
                modalAnimationTime
            );
        } else {
            $target.toggleClass("is-open");
            const openEvent = $.Event("open");
            $target.trigger(openEvent);
        }
    }

    function showModal(modalId, options) {
        $("#" + modalId).addClass("is-open");
        lockHtml();
        if (options && options.success) {
            $("#" + modalId)
                .find("[data-dialog-success]")
                .on("click", function (event) {
                    event.preventDefault();
                    options.success();
                });
        }
    }
    window.showModal = showModal;

    $(document).on("change", "[data-collapse-check]", function () {
        var target = $(this).attr("data-collapse-check"),
            targetElement = $(target),
            parent = $(this).attr("data-parent");

        targetElement.collapse({
            toggle: false,
        });

        if (parent) {
            hideCollapseCheck(parent);
        }

        if (this.checked) {
            targetElement.collapse("show");
            if (targetElement.hasClass("js-block-form")) {
                targetElement.removeClass("is-hide");
            }

            const applications = document.querySelector(
                ".js-application-block"
            );
            if (this.classList.contains("js-application-toggle")) {
                applications.classList.remove("is-hide");
            }
        } else {
            targetElement.collapse("hide");
            if (targetElement.hasClass("js-block-form")) {
                targetElement.addClass("is-hide");
            }
            const applications = document.querySelector(
                ".js-application-block"
            );
            if (this.classList.contains("js-application-toggle")) {
                applications.classList.add("is-hide");
            }
        }
    });

    function hideCollapseCheck(parent) {
        $(parent)
            .find("[data-collapse-check]")
            .each(function () {
                var target = $(this).attr("data-collapse-check");
                if (
                    $(target).collapse &&
                    !$(parent).hasClass("js-collapse-checkboxes")
                ) {
                    $(target).collapse("hide");
                }
            });
    }
    window.hideCollapseCheck = hideCollapseCheck;

    if (window.innerWidth < 1280) {
        var requestWays = document.querySelectorAll(".js-request-way");

        for (var i = 0; i < requestWays.length; i++) {
            scrollToActiveItem(requestWays[i]);
        }

        function scrollToActiveItem(container) {
            var elementOffsetLeft, activeItem, activeItemOffset, scrollLeft;

            activeItem = container.querySelector(".is-active");

            elementOffsetLeft = container.getBoundingClientRect().left;
            activeItemOffset = activeItem.getBoundingClientRect().left;

            scrollLeft = activeItemOffset - elementOffsetLeft;

            container.scrollTo(scrollLeft, 0);
        }
        $(".js-object-info").on("shown.bs.collapse", function () {
            var scrollContainer = this.querySelector(".js-request-way");
            scrollToActiveItem(scrollContainer);
        });
    }

    $("[data-toggle-switch]").on("click", function () {
        var target = $(this).attr("data-target"),
            targetElement = $(target);

        targetElement.collapse({
            toggle: false,
        });

        if (this.checked) {
            targetElement.collapse("show");
        } else {
            targetElement.collapse("hide");
        }
    });

    $(".catalog").on("click", "[data-payment-view]", function (event) {
        event.preventDefault();
        var typeView = $(this).attr("data-payment-view"),
            catalog = $(".catalog");

        switch (typeView) {
            case "month":
                var paymentsHeads = catalog.find("[data-payments-month]");
                paymentsHeads.collapse("show");
                break;

            case "all":
                var paymentsHeads = catalog.find("[data-payments-month]"),
                    paymentsItems = catalog.find("[data-payments-item]");

                paymentsHeads.collapse("show");
                paymentsItems.collapse("show");
                break;

            case "hide":
                var paymentsHeads = catalog.find("[data-payments-month]"),
                    paymentsItems = catalog.find("[data-payments-item]");

                paymentsHeads.collapse("hide");
                paymentsItems.collapse("hide");
                break;
        }
    });

    // загрузка данных при раскрытии
    $(document).on("click", '[data-toggle="collapse-load"]', function (event) {
        event.preventDefault();
        var target = $(this).attr("href"),
            targetElement = $(target);

        if (targetElement.hasClass("in")) {
            targetElement.collapse("hide");
            $(this).addClass("collapsed");

            var childTriggerElements = targetElement.find(
                '[data-toggle="collapse-load"]'
            );

            childTriggerElements.each(function () {
                var hrefValue = $(this).attr("href");
                var childElement = $(hrefValue);
                childElement.collapse("hide");
                $(this).addClass("collapsed");
            });
            return;
        }

        $(this).addClass("is-load");
        targetElement.collapse({
            toggle: false,
        });

        setTimeout(() => {
            $(this).removeClass("is-load");
            targetElement.collapse("show");
            $(this).removeClass("collapsed");
        }, 1000);
    });

    var isToggleShow = false;
    $(document).on("click", "[data-collapse-toggle]", function (event) {
        event.preventDefault();
        var toggleHeads = $(".testimony-list").find("[data-toggle-body]");
        if (toggleHeads.hasClass("in")) {
            isToggleShow = true;
        }

        if (isToggleShow) {
            $(this).removeClass("is-open");
            isToggleShow = false;
            toggleHeads.collapse("hide");
        } else {
            $(this).addClass("is-open");
            isToggleShow = true;
            toggleHeads.collapse("show");
        }
    });

    var isBodiesShow = false;
    $(document).on("click", "[data-collapse-toggle]", function (event) {
        event.preventDefault();
        var toggleHeads = $(this)
            .closest(".js-collapse-list")
            .find("[data-toggle-body]");
        var otherTogglesGroup = $(this)
            .closest(".js-collapse-list")
            .find("[data-collapse-toggle]");

        if (toggleHeads.hasClass("in")) {
            isBodiesShow = true;
        }

        if (isBodiesShow) {
            $(this).removeClass("is-open");
            otherTogglesGroup.removeClass("is-open");
            isBodiesShow = false;
            toggleHeads.collapse("hide");
        } else {
            $(this).addClass("is-open");
            otherTogglesGroup.addClass("is-open");
            isBodiesShow = true;
            toggleHeads.collapse("show");
        }
    });

    // сворачивать двойную стрелку, если все элементы внутри свернуты
    $(document).on(
        "click",
        '.js-collapse-list [data-toggle="collapse"]',
        function (event) {
            const parent = $(this).closest(".js-collapse-list");
            if (!parent) return;
            let closestChild = 0;
            setTimeout(() => {
                const children = $(parent).find("[data-toggle-body]");
                const collapseGroup = $(parent).find("[data-collapse-toggle]");

                $.each(children, function (index, item) {
                    if (!$(item).hasClass("in")) {
                        closestChild++;
                    }
                });

                if (closestChild == children.length) {
                    collapseGroup.removeClass("is-open");
                    isBodiesShow = false;
                } else if (closestChild == 0) {
                    collapseGroup.addClass("is-open");
                    isBodiesShow = true;
                }
            }, 360);
        }
    );

    $(document).on("click", "[data-toggle-account]", function () {
        const toggleHeads = $(".js-entity-account").find("[data-toggle-body]");
        const allBlockCollapses = $(".js-entity-account").find(
            ".collapse.in:not([data-toggle-body])"
        );
        const toggleAllBlocks = $(".js-entity-account").find(
            ".js-collapse-toggle-all"
        );
        const counterAccount = $("[data-toggle-account]:checked").length;

        if (counterAccount >= 2) {
            allBlockCollapses.collapse("hide");
            toggleHeads.collapse("show");
            toggleHeads[0].scrollIntoView({ behavior: "smooth" });
            if (toggleAllBlocks) toggleAllBlocks.addClass("collapsed");
        } else {
            toggleHeads.collapse("hide");
        }
    });
});

// Сладер новостей
(function () {
    var sliderContainer = document.querySelector(".js-news-slider"),
        slideCount;
    if (sliderContainer === null) {
        return;
    }

    slideCount = sliderContainer.getAttribute("data-slide-count");

    if (slideCount == undefined) {
        slideCount = 3;
    }

    var slider = new Swiper(sliderContainer, {
        slidesPerView: slideCount,
        navigation: {
            nextEl: ".js-news-next",
            prevEl: ".js-news-prev",
            disabledClass: "is-disable",
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            420: {
                slidesPerView: 1,
            },
        },
    });
})();

// Слайдер услуг
function connectionSlider() {
    var sliderContainers = document.querySelectorAll(".js-connection-slider");

    for (var i = 0; i < sliderContainers.length; i++) {
        slideCount = sliderContainers[i].getAttribute("data-slide-count");

        if (slideCount == undefined) {
            slideCount = 6;
        }
        initSlider(sliderContainers[i]);
    }

    function initSlider(container) {
        var parentContainer = container.closest(".js-connection-slider-inner");
        var nextBtn = parentContainer.querySelector(".js-connection-next");
        var prevBtn = parentContainer.querySelector(".js-connection-prev");
        const parent = container.closest(".js-connection-slider-container");
        let btnAll;
        if (parent)
            btnAll = parent.querySelector(".js-connetction-slider-all-btn");
        realSlides = container.querySelectorAll(
            ".swiper-slide:not(.swiper-slide-duplicate"
        );

        var slider = new Swiper(container, {
            slidesPerView: slideCount,
            spaceBetween: 10,
            watchOverflow: true,
            observe: true,
            resizeObserver: true,
            updateOnWindowResize: true,
            loop: true,
            navigation: {
                disabledClass: "is-disable",
                hiddenClass: "is-disable",
            },
            breakpoints: {
                1880: {
                    slidesPerView: slideCount - 1,
                },
                1700: {
                    slidesPerView: 4,
                },
                956: {
                    slidesPerView: 3,
                },
                650: {
                    slidesPerView: 2,
                },
                420: {
                    slidesPerView: 2,
                },
            },
        });

        let slideCountMobile;

        if (window.matchMedia("(min-width: 1701px)").matches) {
            slideCountMobile = +slideCount - 1;
            if (realSlides.length <= +slideCount) {
                nextBtn.style.display = "none";
                prevBtn.style.display = "none";
                if (btnAll) btnAll.style.display = "none";
            } else {
                slider.loopCreate();
                nextBtn.style.display = "flex";
                prevBtn.style.display = "flex";
                if (btnAll) btnAll.style.display = "flex";
            }
        }

        if (window.matchMedia("(max-width: 1700px)").matches) {
            slideCountMobile = 3;

            if (realSlides.length <= slideCountMobile + 1) {
                nextBtn.style.display = "none";
                prevBtn.style.display = "none";
                if (btnAll) btnAll.style.display = "none";
            } else {
                slider.loopCreate();
                nextBtn.style.display = "flex";
                prevBtn.style.display = "flex";
                if (btnAll) btnAll.style.display = "flex";
            }
        }

        if (window.matchMedia("(max-width: 956px)").matches) {
            slideCountMobile = 2;

            if (realSlides.length <= slideCountMobile + 1) {
                nextBtn.style.display = "none";
                prevBtn.style.display = "none";
                btnAll.style.display = "none";
            } else {
                slider.loopCreate();
                nextBtn.style.display = "flex";
                prevBtn.style.display = "flex";
                btnAll.style.display = "flex";
            }
        }

        nextBtn.addEventListener("click", function (e) {
            e.preventDefault();
            const index = slider.realIndex + 1;
            slider.slideToLoop(index, 300);
        });

        prevBtn.addEventListener("click", function (e) {
            e.preventDefault();
            const index = slider.realIndex - 1;
            slider.slideToLoop(index, 300);
        });

        if (btnAll)
            btnAll.addEventListener("click", function (e) {
                e.preventDefault();
                const index = slider.realIndex + slideCountMobile;
                slider.slideToLoop(index, 300);
            });
    }
}

connectionSlider();

// Сладер навигации
(function () {
    var sliderContainers = document.querySelectorAll(".js-nav-slider");

    for (var i = 0; i < sliderContainers.length; i++) {
        initSlider(sliderContainers[i]);
    }

    function initSlider(container) {
        var nav = {},
            tabIndex = 0;

        nav.next = container.querySelector(".js-slider-next");
        nav.prev = container.querySelector(".js-slider-prev");

        var slider = new Swiper(container, {
            slidesPerView: "auto",
            on: {
                init: function () {
                    tabIndex = this.realIndex;
                },
                update: function () {
                    [].forEach.call(this.slides, function (slide, i) {
                        slide.addEventListener("click", function () {
                            setTimeout(function () {
                                initSlider(i);
                            }, 100);
                        });
                    });
                },
            },
        });

        [].forEach.call(slider.slides, function (slide, i) {
            slide.addEventListener("click", function () {
                setTimeout(function () {
                    initSlider(i);
                }, 100);
            });
        });

        function initSlider(tabIndex) {
            const href = slider.slides[tabIndex]
                .querySelector("a")
                .href.split("#")[1];
            const tabBlock = document.querySelector("#" + href);

            if (tabBlock.querySelector("[data-shown-slider]")) {
                [].forEach.call(
                    tabBlock.querySelectorAll("[data-shown-slider]"),
                    function (el) {
                        el.swiper.update();
                    }
                );
            }
        }

        function onNextClick(event) {
            event.preventDefault();
            tabIndex++;
            if (tabIndex > slider.slides.length - 1) {
                tabIndex = slider.slides.length - 1;
            }
            slider.slideTo(tabIndex);
            slider.slides[tabIndex].querySelector("a").click();
            initSlider(tabIndex);
        }
        function onPrevClick(event) {
            event.preventDefault();
            tabIndex--;
            if (tabIndex < 0) {
                tabIndex = 0;
            }
            slider.slideTo(tabIndex);
            slider.slides[tabIndex].querySelector("a").click();
            initSlider(tabIndex);
        }

        nav.next.addEventListener("click", onNextClick);
        nav.prev.addEventListener("click", onPrevClick);
    }
})();

// Сладер услуг
(function () {
    var sliderContainer = document.querySelector(".js-service-slider"),
        slider,
        isInitSlider = false;

    if (!sliderContainer) return;

    function onResize() {
        if (window.innerWidth > 959) {
            if (!isInitSlider) {
                return;
            }
            isInitSlider = false;
            slider.destroy();
        } else {
            if (isInitSlider) {
                return;
            }
            isInitSlider = true;
            initServiceSlider();
        }
    }

    function initServiceSlider() {
        slider = new Swiper(sliderContainer, {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: ".js-service-next",
                prevEl: ".js-service-prev",
                disabledClass: "is-disable",
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                520: {
                    slidesPerView: 1,
                },
            },
        });
    }

    onResize();
    $(window).on("resize", throttle(onResize, 150));
})();

// Сладер новостей
(function () {
    var sliderContainer = document.querySelector(".js-notificate-slider");
    var slider = new Swiper(sliderContainer, {
        slidesPerView: 1,
        navigation: {
            nextEl: ".js-notificate-next",
            prevEl: ".js-notificate-prev",
            disabledClass: "is-disable",
        },
    });
})();

// Сладер баннеров на главной
(function () {
    var sliderContainer = document.querySelector(".js-main-slider");
    if (!sliderContainer) {
        return;
    }

    var slider = new Swiper(sliderContainer, {
        slidesPerView: 1,
        navigation: {
            nextEl: ".js-main-next",
            prevEl: ".js-main-prev",
            disabledClass: "is-disable",
        },
        autoHeight: true,
        watchOverflow: true,
        pagination: {
            el: ".js-main-dots",
            type: "bullets",
            clickable: true,
            bulletClass: "dots__item",
            bulletActiveClass: "is-active",
            renderBullet: function (index, className) {
                return (
                    '<div class="' +
                    className +
                    '">' +
                    '<a href="" class="dots__link"></a>' +
                    "</div>"
                );
            },
        },
    });

    var paginationContainer = sliderContainer.querySelector(".js-main-dots");

    if (paginationContainer) {
        if (sliderContainer.swiper.pagination.bullets.length < 2) {
            paginationContainer.style.visibility = "hidden";
            sliderContainer.swiper.destroy();
        } else {
            paginationContainer.style.visibility = "visible";
        }
    }
})();

// Слайдер уведомлений в ЛС
(function () {
    var arrSliderMessages = document.querySelectorAll(".js-messages-container");
    if (arrSliderMessages.length === 0) {
        return;
    }

    [].forEach.call(arrSliderMessages, function (sliderMessage) {
        var sliderContainer = sliderMessage.querySelector(".js-message-slider");
        var nextBtn = sliderMessage.querySelector(".js-message-next");
        var prevBtn = sliderMessage.querySelector(".js-message-prev");

        var slider = new Swiper(sliderContainer, {
            slidesPerView: 2.7,
            spaceBetween: 20,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: nextBtn,
                prevEl: prevBtn,
                disabledClass: "is-disable",
            },
            breakpoints: {
                950: {
                    slidesPerView: 2,
                },
                660: {
                    slidesPerView: 1,
                },
            },
        });
    });
})();

// Слайдер с датами уведомлений в ЛС
(function () {
    var arrSliderContainer = document.querySelectorAll(
        ".js-message-date-slider"
    );
    if (arrSliderContainer.length === 0) {
        return;
    }

    [].forEach.call(arrSliderContainer, function (sliderContainer) {
        var slider = new Swiper(sliderContainer, {
            slidesPerView: 7,
            spaceBetween: 25,
            slideToClickedSlide: true,
            breakpoints: {
                1880: {
                    slidesPerView: 6,
                },
                1680: {
                    slidesPerView: 5,
                },
                1330: {
                    slidesPerView: 4,
                },
                1280: {
                    slidesPerView: 7,
                },
                1200: {
                    slidesPerView: 6,
                },
                1030: {
                    slidesPerView: 5,
                },
                950: {
                    slidesPerView: 4,
                },
                768: {
                    slidesPerView: 3,
                },
                500: {
                    slidesPerView: 2,
                },
                380: {
                    slidesPerView: 1,
                },
            },
        });
    });
})();

// Слайдер с датами на странице history-sap
(function datesHistorySlider() {
    var arrHistorySlider = document.querySelectorAll(".js-history-container");
    if (arrHistorySlider.length > 0) {
        [].forEach.call(arrHistorySlider, function (historySlider) {
            var dateSliderContainer = historySlider.querySelector(
                ".js-history-date-slider"
            );
            var nextBtn = historySlider.querySelector(".js-arm-next");
            var prevBtn = historySlider.querySelector(".js-arm-prev");

            var historyDateSlider = new Swiper(dateSliderContainer, {
                slidesPerView: 7,
                slidesPerGroup: 1,
                spaceBetween: 10,
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: nextBtn,
                    prevEl: prevBtn,
                    disabledClass: "is-disable",
                },
                breakpoints: {
                    900: {
                        slidesPerView: 6,
                        slidesPerGroup: 6,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 5,
                        slidesPerGroup: 5,
                    },
                    580: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                    },
                    430: {
                        slidesPerView: 2.5,
                        slidesPerGroup: 1,
                    },
                    350: {
                        slidesPerView: 2.3,
                        slidesPerGroup: 1,
                    },
                },
            });

            var historyContentContainer = historySlider.querySelector(
                ".js-history-slider-content"
            );

            var sliderHistory = new Swiper(historyContentContainer, {
                slidesPerView: 4,
                slidesPerGroup: 1,
                spaceBetween: 0,
                observer: true,
                observeParents: true,
                breakpoints: {
                    1080: {
                        slidesPerView: 3,
                    },
                    800: {
                        slidesPerView: 2,
                    },
                    580: {
                        slidesPerView: 1,
                    },
                },
                on: {
                    slideChangeTransitionStart: function () {
                        historyDateSlider.slideTo(this.realIndex, 600, false);
                        changeDate(this.realIndex);
                    },
                },
            });

            const dateBtns = historySlider.querySelectorAll(".js-history-date");
            dateBtns[0].classList.add("is-active");
            if (dateBtns.length > 0) {
                [].forEach.call(dateBtns, function (dateBtn, index) {
                    dateBtn.addEventListener("click", function () {
                        changeDate(index);
                        sliderHistory.slideTo(index, 600, false);
                    });
                });
            }

            function changeDate(i) {
                [].forEach.call(dateBtns, function (dateBtn) {
                    dateBtn.classList.remove("is-active");
                });
                dateBtns[i].classList.add("is-active");
            }
        });
    }
})();

// Слайдер с датами на странице Подготовка к ОЗП
function datesSlider() {
    var arrSliderMessages = document.querySelectorAll(
        ".js-arm-slider-container"
    );
    if (arrSliderMessages.length === 0) {
        return;
    }

    [].forEach.call(arrSliderMessages, function (sliderMessage) {
        var sliderContainer = sliderMessage.querySelector(
            ".js-arm-date-slider"
        );
        var nextBtn = sliderMessage.querySelector(".js-arm-next");
        var prevBtn = sliderMessage.querySelector(".js-arm-prev");

        var slider = new Swiper(sliderContainer, {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 10,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: nextBtn,
                prevEl: prevBtn,
                disabledClass: "is-disable",
            },
            breakpoints: {
                550: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 0,
                },
            },
        });
    });
}
datesSlider();

// Слайдер с датами на странице Лицевых счетов
function datesEntitySlider() {
    const arrSliderEntityDates = document.querySelectorAll(
        ".js-entity-date-slider"
    );
    if (arrSliderEntityDates.length === 0) {
        return;
    }

    [].forEach.call(arrSliderEntityDates, function (slider) {
        if (slider.classList.contains("is-init")) return;
        const sliderContainer = slider.closest(
            ".js-entity-date-slider-container"
        );
        const entityInfoContainer = slider.closest(".js-entity-info-container");
        let entityInfoItems;
        let entityDocs;
        if (entityInfoContainer)
            entityInfoItems = entityInfoContainer.querySelectorAll(
                ".js-entity-info-item"
            );
        if (entityInfoContainer)
            entityDocs = entityInfoContainer.querySelectorAll(".js-entity-doc");
        let nextBtn, prevBtn;

        if (sliderContainer) {
            nextBtn = sliderContainer.querySelector(".js-date-next");
            prevBtn = sliderContainer.querySelector(".js-date-prev");
        }

        const swiper = new Swiper(slider, {
            slidesPerView: 1,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: nextBtn,
                prevEl: prevBtn,
                disabledClass: "is-disable",
            },
            on: {
                init: function () {
                    slider.classList.add("is-init");
                },
                slideChange: function () {
                    if (entityInfoItems) {
                        setActiveInfoItem(swiper.activeIndex);
                    }
                    if (entityDocs) {
                        setActiveDoc(swiper.activeIndex);
                    }

                    if (
                        entityInfoItems[swiper.activeIndex].querySelector(
                            ".js-row-hight-container"
                        ) ||
                        entityInfoItems[swiper.activeIndex].classList.contains(
                            "js-row-hight-container"
                        )
                    ) {
                        setCellHeight(
                            entityInfoItems[swiper.activeIndex].parentElement
                        );
                    }
                    if (
                        entityInfoItems[swiper.activeIndex].querySelector(
                            ".js-scroll-container"
                        ) ||
                        entityInfoItems[swiper.activeIndex].classList.contains(
                            "js-scroll-container"
                        )
                    ) {
                        setScrollPositionEnd(
                            entityInfoItems[swiper.activeIndex].parentElement
                        );
                    }
                },
            },
        });

        function setActiveInfoItem(index) {
            [].forEach.call(entityInfoItems, function (item) {
                item.style.display = "none";
            });
            if (entityInfoItems[index]) {
                entityInfoItems[index].style.display = "block";
            }
        }

        function setActiveDoc(index) {
            [].forEach.call(entityDocs, function (item) {
                item.style.display = "none";
            });
            if (entityDocs[index]) {
                entityDocs[index].style.display = "block";
            }
        }
    });
}
datesEntitySlider();

// загрузка файлов
(function downloadDocument() {
    const documents = document.querySelectorAll(".js-document-pdf");
    [].forEach.call(documents, function (item) {
        const preloader = item.querySelector(".js-arm-preloader");
        setTimeout(function () {
            if (preloader) {
                preloader.classList.add("is-hidden");
            }
        }, 1000);
    });
})();

// Тут функция с аяксом
function requestAjax(option) {
    /*
        option.url - путь, куда отпралять запрос
        option.data - данные с формы
        option.onSuccess - callback на упешный аякс
        option.onError - callback - на ошибочный
    */

    /*TODO: Для отладки. Удалить в конечной итоговой реализцации */
    var isObjectMessage = false;
    if (option.form[0].getAttribute("data-object-message") !== null) {
        isObjectMessage = true;
    }

    $.ajax({
        url: option.url || "/",
        method: "post",
        data: option.data,
        dataType: "json",
        success: function (response) {
            if (true) {
                option.onSuccess(response);
            } else {
                option.onError(response);
            }
        },
        error: function (response) {
            /*TODO: Для отладки. Удалить в конечной итоговой реализцации */
            if (isObjectMessage) {
                response.message = {};
            }

            console.error(response);
            option.onError(response);
        },
    });
}

(function () {
    /*
        form - селектор формы - обязательный параметр
        requestFunction - функция отправки данных формы - обязательный параметр
        option - доп настройки
    */
    var FormSubmiter = function (form, requestFunction) {
        var API = {},
            setting = {};

        if (form == undefined) {
            console.error("form undefined");
            return;
        }

        function init() {
            setting.isSubmit = false;
            setting.isAjax = true;
            form.checkForm();

            setting.submitButton = form.find("[data-submit]");
            setting.messageElement = form.find("[data-message]");
            setting.messageElementTitle = setting.messageElement.find(
                "[data-message-title]"
            );
            setting.messageElementText = setting.messageElement.find(
                "[data-message-text]"
            );
            setting.messageElementClose = setting.messageElement.find(
                "[data-message-close]"
            );

            setting.url = getFormSubmitUrl();

            checkRequestFunction();

            setEvent();
        }

        function checkRequestFunction() {
            if (requestFunction == undefined) {
                setting.isAjax = false;
            }
        }

        function getFormSubmitUrl() {
            if (
                form[0].hasAttribute("action") &&
                form[0].getAttribute("action") !== ""
            ) {
                return form[0].getAttribute("action");
            } else if (
                form[0].hasAttribute("data-action") &&
                form[0].getAttribute("data-action") !== ""
            ) {
                return form[0].getAttribute("data-action");
            }
            return null;
        }

        function onSubmit(event) {
            if (setting.isSubmit) {
                return;
            }

            if (setting.submitButton.hasClass("is-disable")) {
                event.preventDefault();
                unValidateForm();
            } else {
                if (setting.isAjax) {
                    event.preventDefault();
                    prepareRequest();
                }
            }
        }

        function unValidateForm() {
            form.checkForm("validate");

            form.addClass("is-error");
            form.removeClass("is-success");

            setting.submitButton.addClass("is-animate");

            setTimeout(function () {
                setting.submitButton.removeClass("is-animate");
            }, 400);
        }

        function prepareRequest() {
            var requestOptions = {};
            setting.isSubmit = true;

            requestOptions.form = form;
            requestOptions.data = form.serializeArray();
            requestOptions.url = setting.url;

            requestOptions.onSuccess = onSuccess;
            requestOptions.onError = onError;

            form.addClass("is-overlay");
            form.removeClass("is-error");
            setting.submitButton.addClass("is-overlay");

            requestFunction(requestOptions);
        }

        function clearForm() {
            form[0].reset();
            form.removeClass("is-overlay");

            form.find(".is-filled").removeClass("is-filled");
            form.checkForm();

            form.find(".is-error").removeClass("is-error");

            setting.submitButton.removeClass("is-overlay");
            setting.submitButton.addClass("is-disable");
        }

        function onSuccess(message) {
            clearForm();

            if (!form.hasClass("js-ajax-form--no-hide")) {
                form.hide();
            }

            form.trigger("ajax.success", [message]);

            if (setting.messageElement !== undefined) {
                if (typeof message == "object") {
                    if (message.title && setting.messageElementTitle.length) {
                        setting.messageElementTitle.html(message.title);
                    }
                    if (message.text && setting.messageElementText.length) {
                        setting.messageElementText.html(message.text);
                    }
                } else {
                    setting.messageElement.html(message);
                }

                setting.messageElement.show();
                setting.messageElement.addClass("is-success");

                if (!setting.messageElementClose.length) {
                    setTimeout(function () {
                        setting.messageElement.hide();
                        setting.messageElement.removeClass("is-success");
                        form.show();
                    }, 2500);
                }
            }
        }
        function onError(response) {
            var message;

            message = response.message;
            setting.isSubmit = false;

            if (message == undefined) {
                // message = 'Что-то пошло не так';
            }

            form.removeClass("is-overlay");
            setting.submitButton.removeClass("is-overlay");
            setting.submitButton.addClass("is-animate");

            if (setting.messageElement !== undefined) {
                if (typeof message == "object") {
                    if (message.title && setting.messageElementTitle.length) {
                        setting.messageElementTitle.html(message.title);
                    }
                    if (message.text && setting.messageElementText.length) {
                        setting.messageElementText.html(message.text);
                    }
                } else {
                    setting.messageElement.html(message);
                }

                setting.messageElement.addClass("is-error");
                setting.messageElement.show();

                if (!setting.messageElementClose.length) {
                    setTimeout(function () {
                        setting.submitButton.removeClass("is-animate");
                        setting.submitButton.removeClass("is-overlay");
                        setting.messageElement.hide();
                        setting.messageElement.removeClass("is-error");
                    }, 5000);
                }
            }
        }

        function onCloseMessage(event) {
            event.preventDefault();
            clearForm();
            setting.messageElement.hide();
        }

        function setEvent() {
            form.on("submit", onSubmit);

            if (setting.messageElementClose.length) {
                setting.messageElementClose.on("click", onCloseMessage);
            }
        }

        init();

        return API;
    };

    if ($(".js-ajax-form").length) {
        $(".js-ajax-form").each(function () {
            var formSubmiter = new FormSubmiter($(this), requestAjax);
        });
    }

    if ($(".js-form").length) {
        $(".js-form").each(function () {
            var formSubmiter = new FormSubmiter($(this));
        });
    }

    // шаги на странице добавления ЛС
    if ($(".js-line-form").length) {
        $(".js-line-form").each(function () {
            var formSubmiter = new FormSubmiter($(this));
        });
    }

    // форма на странице Добавить заявителя
    (function () {
        const typeOfDeclarer = document.querySelector(".js-type-of-declarer");
        const whoOfDeclarerContainer = document.querySelector(
            ".js-who-declarer-container"
        );
        const whoOfDeclarer = document.querySelectorAll("[data-who-declarer]");
        const showBlocksCheckbox = document.querySelector(
            ".js-check-show-blocks"
        );

        const blocksOfFormArr = document.querySelectorAll("[data-block-form]");

        if (!typeOfDeclarer) return;

        if (whoOfDeclarerContainer) {
            whoOfDeclarerContainer.style.display = "none";
        }

        const optionsOfSelect = typeOfDeclarer.querySelectorAll(
            ".js-option-of-select"
        );
        var activeOption = optionsOfSelect[typeOfDeclarer.selectedIndex];
        var valueBtn = whoOfDeclarer[0].getAttribute("data-who-declarer");

        showFormBlocks(activeOption, valueBtn);

        typeOfDeclarer.addEventListener("change", function () {
            activeOption = optionsOfSelect[typeOfDeclarer.selectedIndex];
            whoOfDeclarer[0].checked = true;
            valueBtn = whoOfDeclarer[0].getAttribute("data-who-declarer");

            if (activeOption.hasAttribute("data-show-checkbox")) {
                whoOfDeclarerContainer.style.display = "flex";
            } else {
                whoOfDeclarerContainer.style.display = "none";
            }

            showFormBlocks(activeOption, valueBtn);
            openAllBlocks();
        });

        Array.prototype.slice.call(whoOfDeclarer).forEach(function (btn) {
            btn.addEventListener("click", function () {
                const valueBtn = btn.getAttribute("data-who-declarer");

                showFormBlocks(activeOption, valueBtn);
            });
        });

        showBlocksCheckbox.addEventListener("change", function () {
            const checkBlocks = document.querySelectorAll("[data-check-block]");
            Array.prototype.slice.call(checkBlocks).forEach(function (block) {
                const attr = block.getAttribute("data-check-block");
                if (showBlocksCheckbox.checked) {
                    if (attr === "check") {
                        block.classList.remove("is-hide");
                    } else {
                        block.classList.add("is-hide");
                    }
                } else {
                    if (attr === "check") {
                        block.classList.add("is-hide");
                    } else {
                        block.classList.remove("is-hide");
                    }
                }
            });
        });

        function showFormBlocks(option, dataAttribute) {
            const numbersOfBlock = option.getAttribute("data-" + dataAttribute);
            const numbersOfBlockArray = numbersOfBlock.split(",");

            removeBlocks();

            [].forEach.call(numbersOfBlockArray, function (numberOfBlock) {
                Array.prototype.slice
                    .call(blocksOfFormArr)
                    .forEach(function (block) {
                        const numberOfBlockContainer =
                            block.getAttribute("data-block-form");

                        if (numberOfBlockContainer === numberOfBlock) {
                            block.classList.remove("is-hide");
                        }
                    });
            });

            if ($(".js-ajax-form").length) {
                $(".js-ajax-form").each(function () {
                    var formSubmiterDeclarer = new FormSubmiter(
                        $(this),
                        requestAjax
                    );
                });
            }
        }

        var toggleVies = $(".declarer-add__container").find(
            "[data-collapse-toggle]"
        );
        var toggleHeads = $(".declarer-add__container").find(
            "[data-toggle-body]"
        );

        function openAllBlocks() {
            if (window.matchMedia("(max-width: 1024px)").matches) {
                toggleHeads.collapse("hide");
                toggleVies.removeClass("is-open");
            } else {
                toggleHeads.collapse("show");
                toggleVies.addClass("is-open");
            }
        }

        function removeBlocks() {
            for (var i = 0; i < blocksOfFormArr.length; i++) {
                blocksOfFormArr[i].classList.add("is-hide");
            }
        }

        if (window.matchMedia("(max-width: 1024px)").matches) {
            toggleVies.removeClass("is-open");
            toggleHeads.collapse("hide");
        }
    })();
})();

(function () {
    var perosnalHead = document.querySelector(".js-personal-head");
    if (perosnalHead !== null) {
        setTimeout(function () {
            perosnalHead.classList.add("is-change");
        }, 10000);
    }
})();

function initMap() {
    var mapCardContainer = document.getElementById("mapCard");

    if (mapCardContainer == null) {
        return;
    }

    var mapCard = new google.maps.Map(mapCardContainer, {
        center: { lat: 56.015319, lng: 92.862668 },
        zoom: 16,
        disableDefaultUI: true,
        zoomControl: true,
        stylecrollwheel: false,
    });
}
initMap();

(function () {
    var FileUploadManager = function (container, options) {
        var API = {},
            setting = {};

        if (!container) {
            return;
        }

        function _checkOption(option, defaultValue) {
            if (defaultValue == undefined) {
                defaultValue = null;
            }
            return option ? option : defaultValue;
        }

        function getOptions() {
            if (options == undefined) {
                options = {};
            }

            setting.onChange = _checkOption(options.onChange);
            setting.onComplete = _checkOption(options.onComplete);
            setting.onRemove = _checkOption(options.onRemove);
        }

        function init() {
            getOptions();

            setting.files = [];
            setting.fileIndex = 0;
            setting.input = container.querySelector("[data-file-input]");
            setting.inputItem = container.querySelector("[data-input-item]");
            setting.isMultiple = setting.input.multiple;

            setEvents();
        }

        function showButton() {
            setting.inputItem.classList.remove("is-hide");
            setting.input.classList.remove("is-file");
            setting.input.classList.remove("is-error");
            setting.input.classList.remove("is-success");
        }
        function hideButton() {
            if (setting.inputItem) setting.inputItem.classList.add("is-hide");
            setting.input.classList.add("is-file");
            setting.input.classList.remove("is-error");
            setting.input.classList.add("is-success");
        }

        function renderItems(files) {
            if (setting.input.classList.contains("js-add-file-down")) {
                if (container.parentElement.querySelector(".files-loader")) {
                    container.parentElement
                        .querySelector(".files-loader")
                        .append(createItems(files, true));
                }
            } else {
                container.prepend(createItems(files));

                if (container.classList.contains("js-add-right")) {
                    if (setting.files.length === 1) {
                        container.parentElement.classList.add("is-added");
                    }
                }
            }
        }

        function createItems(files, addDown = false) {
            var fragment = document.createDocumentFragment();

            for (var i = 0; i < files.length; i++) {
                var item = document.createElement("div");

                item.className = "files-loader__item";
                if (addDown) item.classList.add("is-item-down");
                item.innerHTML = createItem(files[i], setting.fileIndex);

                fragment.append(item);
            }

            return fragment;
        }

        function createItem(file) {
            return (
                '<div class="file-view">' +
                '<div class="file-view__icon is-success fab-icon">' +
                '<i class="icon icon--check"></i>' +
                "</div>" +
                '<div class="file-view__body">' +
                '<div class="file-view__title h5">' +
                file.name +
                "</div>" +
                '<div class="file-view__caption">5-Июля 2019 — 11:08 12 мб</div>' +
                "</div>" +
                '<a href="" class="file-view__remove button-icon button-icon--th-help" data-file-remove="' +
                file.index +
                '">' +
                '<i class="icon icon--remove-fill"></i>' +
                "</a>" +
                "</div>"
            );
        }

        function removeItem(index) {
            var removeFile;
            for (var i = 0; i < setting.files.length; i++) {
                if (setting.files[i].index == index) {
                    removeFile = setting.files[i];
                    setting.files.splice(i, 1);
                    return removeFile;
                    break;
                }
            }

            if (setting.files.length == 0) {
                setting.input.value = "";
            }
        }

        function reset() {
            setting.files = [];
            setting.value = "";

            var items = container.querySelectorAll(".files-loader__item");
            for (var i = 0; i < items.length; i++) {
                if (!items[i].hasAttribute("data-input-item")) {
                    container.removeChild(items[i]);
                }
            }
            var newBlockParent = container.closest(
                ".js-file-new-block-container"
            );
            if (newBlockParent) {
                var newBlockItems =
                    newBlockParent.querySelectorAll(".js-file-new-block");
                for (var i = 0; i < newBlockItems.length; i++) {
                    if (
                        i == 0 &&
                        !newBlockItems[i].classList.contains(
                            "js-file-new-block-trigger"
                        )
                    ) {
                        newBlockItems[i].classList.add(
                            "js-file-new-block-trigger"
                        );
                        newBlockItems[i].style.display = "none";
                    } else if (i > 0) {
                        newBlockItems[i].remove();
                    }
                }
            }
            showButton();
        }

        function addNewBlock(input) {
            const inputContainer = input.closest(
                ".js-file-new-block-container"
            );
            if (inputContainer) {
                const newBlockContainer = inputContainer[0].querySelector(
                    ".js-file-new-block-trigger"
                );

                const copyItem = newBlockContainer.cloneNode(true);
                copyItem.style.display = "";
                copyItem.classList.remove("js-file-new-block-trigger");
                inputContainer[0].appendChild(copyItem);

                new FileUploadManager(copyItem, {});
            }
        }

        function onChange() {
            for (var i = 0; i < this.files.length; i++) {
                this.files[i].index = setting.fileIndex;
                setting.fileIndex++;
                setting.files.push(this.files[i]);
            }

            renderItems(this.files);

            if (!setting.isMultiple) {
                hideButton();
            }

            if (setting.onChange) {
                var state = {};

                state.currentFiles = this.files;
                state.allFiles = setting.files;

                setting.onChange(state);
            }

            if ($(this).hasClass("js-add-new-block")) addNewBlock($(this));

            $(this).click();
        }

        function onRemove(event) {
            event.preventDefault();
            var removeFile;
            var fileIndex = $(this).attr("data-file-remove");
            const showMainButton = !$(this)
                .closest(".files-loader__item")
                .hasClass("is-item-down");

            var newBlockParent = this.closest(".js-file-new-block-container");
            if (newBlockParent) {
                var newBlockItems =
                    newBlockParent.querySelectorAll(".js-file-new-block");
                for (var i = 0; i < newBlockItems.length; i++) {
                    if (
                        i == 0 &&
                        !newBlockItems[i].classList.contains(
                            "js-file-new-block-trigger"
                        )
                    ) {
                        newBlockItems[i].classList.add(
                            "js-file-new-block-trigger"
                        );
                        newBlockItems[i].style.display = "none";
                    } else if (i > 0) {
                        newBlockItems[i].remove();
                    }
                }
            }

            $(this).closest(".files-loader__item").remove();

            removeFile = removeItem(parseInt(fileIndex));
            if (showMainButton) showButton();

            if (setting.onRemove) {
                setting.onRemove(removeFile, setting.files.length);
            }

            $(setting.input).dblclick();
        }

        function setEvents() {
            setting.input.addEventListener("change", onChange);
            $(container).on("click", "[data-file-remove]", onRemove);
        }

        function getFiles() {
            return setting.files;
        }

        init();

        API.getFiles = getFiles;
        API.reset = reset;
        return API;
    };

    window.FileUploadManager = FileUploadManager;

    function defaultInit() {
        var items = document.querySelectorAll("[data-file-loader]");

        for (var i = 0; i < items.length; i++) {
            var fileManager = new FileUploadManager(items[i], {
                onChange: function (state) {
                    // console.log(state.currentFiles); // Текущие добаленные файлы
                    // console.log(state.allFiles); // Все файлы
                },
                onRemove: function (removeFile) {},
            });
        }
    }

    defaultInit();
})();

(function () {
    var StepWatcher = function (container, options) {
        var API = {},
            setting = {};

        if (container === undefined) {
            return;
        }

        function _checkOption(option, defaultValue) {
            if (defaultValue == undefined) {
                defaultValue = null;
            }
            return option ? option : defaultValue;
        }

        function getOptions() {
            if (options == undefined) {
                options = {};
            }

            setting.onInit = _checkOption(options.onInit);
            setting.onComplete = _checkOption(options.onComplete);
            setting.onClear = _checkOption(options.onClear);
        }

        function init() {
            getOptions();

            setting.stepBody = container.querySelector("[data-step-body]");

            if (setting.onInit !== null) {
                setting.onInit(container);
            }
        }

        function updateBodyContent(bodyContent) {
            setting.stepBody.innerHTML = bodyContent;
        }
        function show() {
            container.classList.add("is-active");
        }
        function hide() {
            container.classList.remove("is-active");
        }

        function showOverlay() {
            container.classList.add("is-overlay");
        }
        function hideOverlay() {
            container.classList.remove("is-overlay");
        }

        function clearForm() {
            var inputs = container.querySelectorAll("input");

            for (var i = 0; i < inputs.length; i++) {
                switch (inputs[i].type) {
                    case "radio":
                        inputs[i].checked = false;
                        break;

                    case "checkbox":
                        inputs[i].checked = false;
                        break;

                    default:
                        inputs[i].value = "";
                        break;
                }
            }

            if (setting.onClear !== null) {
                setting.onClear(container);
            }
        }

        init();

        API.showOverlay = showOverlay;
        API.hideOverlay = hideOverlay;

        API.show = show;
        API.hide = hide;
        API.clearForm = clearForm;

        API.updateBodyContent = updateBodyContent;

        return API;
    };

    var RequestWatcher = function (container, options) {
        var API = {},
            setting = {};

        if (container === null) {
            return;
        }

        function _checkOption(option, defaultValue) {
            if (defaultValue == undefined) {
                defaultValue = null;
            }
            return option ? option : defaultValue;
        }

        function getOptions() {
            if (options == undefined) {
                options = {};
            }

            setting.afterInit = _checkOption(options.afterInit);
            setting.onSubmit = _checkOption(options.onSubmit);
        }

        function init() {
            getOptions();

            setting.isComplete = false;
            setting.steps = [];
            setting.activeStep = 0;
            setting.fileManagers = [];

            setting.submitButton = container.querySelector(
                "[data-submit-request]"
            );

            disableButton();
            setEvents();
        }

        function disableButton() {
            setting.submitButton.classList.add("is-disable");
            setting.isComplete = false;
        }
        function enableButton() {
            setting.submitButton.classList.remove("is-disable");
            setting.isComplete = true;
        }

        function showOverlay() {
            container.classList.add("is-overlay");
        }
        function hideOverlay() {
            container.classList.remove("is-overlay");
        }

        function setStep(stepIndex) {
            setting.activeStep = stepIndex;
            for (var i = setting.steps.length - 1; i >= stepIndex; i--) {
                setting.steps[i].hide();
                setting.steps[i].clearForm();
            }
            setting.steps[stepIndex].show();
        }

        function addStep(step) {
            setting.steps.push(step);
        }
        function nextStep() {
            if (setting.activeStep > setting.steps.length - 1) {
                return;
            }
            setting.activeStep++;
            setting.steps[setting.activeStep].show();
        }

        function addFileManager(manager) {
            setting.fileManagers.push(manager);
        }

        function reset() {
            for (var i = 0; i < setting.steps.length; i++) {
                setting.steps[i].clearForm();
            }
            for (var i = 0; i < setting.fileManagers.length; i++) {
                setting.fileManagers[i].reset();
            }
            setStep(0);
        }

        function getActiveStep() {
            return setting.activeStep;
        }

        function getStep(stepIndex) {
            return setting.steps[stepIndex];
        }

        function onSubmit(event) {
            if (!setting.isComplete) {
                return;
            }

            event.preventDefault();
            if (setting.onSubmit) {
                setting.onSubmit();
            }
        }

        function setEvents() {
            setting.submitButton.addEventListener("click", onSubmit);
        }

        API.getActiveStep = this.getActiveStep = getActiveStep;
        API.getStep = this.getStep = getStep;
        API.addStep = this.addStep = addStep;
        API.nextStep = this.nextStep = nextStep;
        API.setStep = this.setStep = setStep;

        API.showOverlay = this.showOverlay = showOverlay;
        API.hideOverlay = this.hideOverlay = hideOverlay;

        API.enableButton = this.enableButton = enableButton;
        API.disableButton = this.disableButton = disableButton;
        API.addFileManager = this.addFileManager = addFileManager;
        API.reset = this.reset = reset;

        init();

        if (setting.afterInit) {
            setting.afterInit(this);
        }

        return API;
    };

    var requestWatcher = new RequestWatcher(
        document.getElementById("requestForm"),
        {
            afterInit: function (instance) {
                initDeclareStep(instance);
                initRequestTypeStep(instance);
                initDataStep(instance);
                initDocsStep(instance);
                initSubmitStep(instance);
            },
            onSubmit: function () {
                var agreementCheckbox =
                    document.getElementById("agreementCheckbox");
                if (agreementCheckbox) {
                    if (!agreementCheckbox.checked) {
                        agreementCheckbox.classList.add("is-error");
                        return;
                    }
                    agreementCheckbox.classList.remove("is-error");
                }
                requestWatcher.showOverlay();
                setTimeout(function () {
                    requestWatcher.hideOverlay();
                    showModal("modalSuccessMessage");
                    requestWatcher.reset();
                }, 1500);
            },
        }
    );

    //Шаг заявителя
    function initDeclareStep(watcher) {
        var prevRadio;
        var declarantStep = new StepWatcher(
            document.getElementById("declarantStep"),
            {
                onInit: function (container) {
                    var declarantCheckers = container.querySelectorAll(
                        "[data-step-variant]"
                    );

                    for (var i = 0; i < declarantCheckers.length; i++) {
                        declarantCheckers[i].addEventListener(
                            "change",
                            onDeclarantTypeChange
                        );
                    }
                },
            }
        );

        function onDeclarantTypeChange(event) {
            var currentStep = watcher.getActiveStep();

            if (currentStep == 0) {
                // По завершению шага, обновляем через ajax (или другим способом) тело следующего шага
                declarantStep.showOverlay();
                setTimeout(function () {
                    declarantStep.hideOverlay();
                    if (currentStep == 0) {
                        watcher.nextStep();
                    }
                }, 1500);
            } else {
                var currentRadio = this;
                if (prevRadio) {
                    prevRadio.checked = true;
                }

                window.showModal("modalDialog", {
                    success: function () {
                        declarantStep.showOverlay();
                        currentRadio.checked = true;
                        prevRadio = currentRadio;
                        closeModals();

                        setTimeout(function () {
                            // Для примера!!! Удалить content в конечной реализации
                            var content = $("#requestTypeStep")
                                .find(".request-section__body")
                                .html();
                            watcher.getStep(1).updateBodyContent(content);
                            declarantStep.hideOverlay();
                            watcher.setStep(1);
                        }, 1500);
                    },
                });
            }
            if (!prevRadio) {
                prevRadio = this;
            }
        }
        watcher.addStep(declarantStep);
    }

    //Шаг заявки
    function initRequestTypeStep(watcher) {
        var requestTypeStep = new StepWatcher(
            document.getElementById("requestTypeStep"),
            {
                onInit: function (container) {
                    $("#requestTypeStep").on(
                        "click",
                        "[data-step-variant]",
                        onRequestTypeChange
                    );
                },
            }
        );

        function onRequestTypeChange() {
            var currentStep = watcher.getActiveStep();
            if (currentStep == 1) {
                watcher.nextStep();
            }
        }
        watcher.addStep(requestTypeStep);
    }

    //Шаг Данных
    function initDataStep(watcher) {
        var dataStep = new StepWatcher(document.getElementById("dataStep"), {
            onInit: function (container) {
                var dataCheckers = container.querySelectorAll(
                    "[data-step-variant]"
                );

                if (!dataCheckers) {
                    return;
                }

                for (var i = 0; i < dataCheckers.length; i++) {
                    dataCheckers[i].addEventListener("change", onDataChange);
                }
            },
            onClear: function (container) {
                window.hideCollapseCheck(container);
            },
        });

        function onDataChange() {
            var currentStep = watcher.getActiveStep();
            if (currentStep == 2) {
                watcher.nextStep();
            }
        }
        watcher.addStep(dataStep);
    }

    //Шаг документов
    function initDocsStep(watcher) {
        var docsStep = new StepWatcher(document.getElementById("docsStep"), {
            onInit: function (container) {
                var docLoaders = container.querySelectorAll(
                        "[data-step-file-loader]"
                    ),
                    docLoadersInputs =
                        container.querySelectorAll("[data-file-input]");

                for (var i = 0; i < docLoaders.length; i++) {
                    var item = docLoaders[i];
                    var fileManager = new FileUploadManager(item, {
                        onChange: function (state) {
                            setTimeout(function () {
                                var isFilesLoad = true;
                                for (
                                    var i = 0;
                                    i < docLoadersInputs.length;
                                    i++
                                ) {
                                    docLoadersInputs[i];
                                    if (item.value == "") {
                                        isFilesLoad = false;
                                    }
                                }

                                if (isFilesLoad) {
                                    var currentStep = watcher.getActiveStep();
                                    if (currentStep == 3) {
                                        watcher.nextStep();
                                    }
                                }
                            }, 1000);
                        },
                        onRemove: function (removeFile, files) {},
                    });
                    watcher.addFileManager(fileManager);
                }
            },
        });
        watcher.addStep(docsStep);
    }

    //Шаг подтверждения
    function initSubmitStep(watcher) {
        var submitStep = new StepWatcher(
            document.getElementById("submitStep"),
            {
                onInit: function (container) {
                    var submitCheckers = container.querySelectorAll(
                        "[data-step-variant]"
                    );

                    for (var i = 0; i < submitCheckers.length; i++) {
                        submitCheckers[i].addEventListener(
                            "change",
                            onSubmitStepChange
                        );
                    }
                },
                onClear: function (container) {
                    window.hideCollapseCheck(container);
                },
            }
        );

        function onSubmitStepChange() {
            var currentStep = watcher.getActiveStep();
            watcher.enableButton();
        }
        watcher.addStep(submitStep);
    }
})();

//селект с меткой в поле
(function () {
    $("[data-selectpicker-label]")
        .on("loaded.bs.select", function (instance) {
            if ($(this).val() !== "") {
                setSelectValue($(this));
            }
        })
        .on("changed.bs.select", function (instance) {
            setSelectValue($(this));
        });

    function setSelectValue(select) {
        var filterOption = select.parent().find(".filter-option"),
            currentHTML = filterOption.html(),
            newHTML = "",
            subString =
                "<strong>" +
                select.attr("data-selectpicker-label") +
                ": </strong>";

        newHTML = subString + currentHTML;

        filterOption.html(newHTML);
    }
})();

(function () {
    var CircleTimer = function (container, options) {
        var API = {},
            setting = {};

        setting.time = 60000;
        setting.currentTime = 0;

        if (!container) {
            console.error("container is undefined");
            return;
        }

        function getOptions() {
            if (options == undefined) {
                options = {};
            }

            setting.time = _checkOption(options.time, setting.time);
            setting.onEnd = _checkOption(options.onEnd);
        }

        function _checkOption(option, defaultValue) {
            if (defaultValue == undefined) {
                defaultValue = null;
            }
            return option ? option : defaultValue;
        }

        function init() {
            setting.timerValue = container.querySelector(
                "[data-circle-timer-time]"
            );
            setting.timerProgress = container.querySelector(
                "[data-circle-timer-progress]"
            );
            setting.timerShape = container.querySelector(
                "[data-circle-timer-shape]"
            );

            getOptions();
            initTimer();
        }

        function setTime(time) {
            setting.time = parseInt(time, 10);
        }

        function setTimerValue(value) {
            setting.timerValue.innerHTML = value;
        }

        function onEndTimeout() {
            if (setting.onEnd) {
                setting.onEnd();
            }
        }

        function initTimer() {
            var fixTime = (setting.time / 1000) * 0.05; //Есть небольшое расхождение во времени анимации и таймера пропорциональное общему времени. Это число корректирует это расхождение +-. Множитель 0.05 - подобран визуально.
            setting.timerProgress.classList.add("is-countdown");
            setting.timerShape.style.transitionDuration =
                setting.time / 1000 + fixTime + "s";
            setting.currentTime = setting.time / 1000;

            setTimerValue(setting.currentTime);

            var interval = setInterval(function () {
                setting.currentTime--;
                setTimerValue(setting.currentTime);

                if (setting.currentTime <= 0) {
                    clearInterval(interval);
                    onEndTimeout();
                }
            }, 1000);
        }

        init();

        return API;
    };

    if (document.querySelector("[data-circle-timer]")) {
        window.circleTimer = new CircleTimer(
            document.querySelector("[data-circle-timer]"),
            {
                time: 300000,
                onEnd: function () {},
            }
        );
    }
})();

(function () {
    var testimonyItems = document.querySelectorAll("[data-testimony-item]");

    for (var i = 0; i < testimonyItems.length; i++) {
        (function (item) {
            var testimonySubmit = item.querySelector("[data-testimony-submit]"),
                testimonySubmitText = testimonySubmit.querySelector("span"),
                testimonyMessage = item.querySelector(
                    "[data-testimony-message]"
                ),
                testimonyFileManager = item.querySelector(
                    "[data-testimony-file-loader]"
                ),
                isFileCheck = false;

            if (testimonyFileManager) {
                var fileManager = new FileUploadManager(testimonyFileManager, {
                    onChange: function (state) {
                        isFileCheck = true;
                    },
                    onRemove: function (removeFile) {
                        isFileCheck = false;
                    },
                });
            } else {
                isFileCheck = true;
            }

            testimonySubmit.addEventListener("click", function (event) {
                event.preventDefault();
                if (!isFileCheck) {
                    testimonyMessage.innerHTML =
                        "Прикрепите файл. Детерминант усиливает интеграл Фурье. Непрерывная функция порождает расходящийся ряд. Детерминант усиливает интеграл Фурье.";
                    testimonyMessage.style.display = "block";
                    return;
                }
                testimonySubmit.classList.add("is-overlay");

                setTimeout(function () {
                    testimonySubmitText.innerHTML = "Редактировать показания";
                    testimonySubmit.classList.remove("is-overlay");
                    testimonyMessage.innerHTML =
                        "Показания переданы успешно 15.10.2019 16:45:15";
                    testimonyMessage.style.display = "block";
                }, 1500);
            });
        })(testimonyItems[i]);
    }
})();

$("[data-personal-remove]").on("click", function (event) {
    event.preventDefault();
    var item = $(this).closest("[data-personal-item]");
    item.addClass("is-remove");

    setTimeout(function () {
        item.remove();
    }, 250);
});

// Опросник (questionnaire)
$(document).ready(function () {
    const containers = document.querySelectorAll(".js-faq-container");

    [].forEach.call(containers, function (container) {
        let allQueations = $(".card-questionnaire__step", container).length;
        let counter = 1;
        setCounters();
        disableButtons();
        $(
            ".card-questionnaire__next,.js--click-next-question",
            container
        ).click(function () {
            counter++;
            setCounters();
            $(".card-questionnaire", container).attr("data-active", counter);
            $(
                ".card-questionnaire__step-" + (counter - 1),
                container
            ).slideUp();
            $(".card-questionnaire__step-" + counter, container).slideDown();
            disableButtons();
        });
        $(".card-questionnaire__prev", container).click(function () {
            counter--;
            setCounters();
            $(".card-questionnaire", container).attr("data-active", counter);
            $(
                ".card-questionnaire__step-" + (counter + 1),
                container
            ).slideUp();
            $(".card-questionnaire__step-" + counter, container).slideDown();
            disableButtons();
        });
        $(".card-questionnaire .js--show-result", container).click(function () {
            $(".card-questionnaire__step-" + counter, container).slideUp();
            setTimeout(function () {
                counter = 1;
                setCounters();
                disableButtons();
                $(".card-questionnaire", container)
                    .attr("data-active", counter)
                    .addClass("card-questionnaire--results");
                $(".card-questionnaire__step-1", container).slideDown();
            }, 400);
        });

        $(".js--run-questionnaire", container).click(function () {
            var r = $(this)
                .closest(".aside__item")
                .slideUp()
                .nextAll(".card-questionnaire-wrap");
            r.slideDown();
            setTimeout(function () {}, 400);
        });

        function setCounters() {
            $(".card-questionnaire__text", container).text(
                counter + " из " + allQueations
            );
        }

        function disableButtons() {
            $(".card-questionnaire__prev", container).removeClass("is-hide");
            $(".card-questionnaire__next", container).removeClass("is-hide");
            $(".card-promo__button", container).removeClass("is-show");

            if (counter === allQueations) {
                $(".card-questionnaire__next", container).addClass("is-hide");
                $(".card-promo__button", container).addClass("is-show");
            } else if (counter === 1) {
                $(".card-questionnaire__prev", container).addClass("is-hide");
            }
        }
    });
});

// Селект с инпутом (пример на order-master личный счет)
$(document).ready(function () {
    setTimeout(function () {
        // при изменении селекта ставим его значение в инпут
        $(".b-select--w-input select").change(function () {
            var input =
                this.parentElement.previousSibling.previousSibling
                    .lastElementChild;
            input.value = this.options[this.selectedIndex].text;
            //- input.classList.add('js--not-empty')
        });

        // обозначаем фокусировку в инпуте классом
        $(".b-select--w-input input").focus(function () {
            $(this).parent().addClass("js--open");
        });

        $(".b-select--w-input input").on("keydown", function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                this.blur();
            }
        });

        // оставляем открытым дропдаун при клике в инпут
        $(".b-select__input").click(function () {
            var t = $(this),
                timer;
            clearTimeout(timer);
            setTimeout(function () {
                t.nextAll(".bootstrap-select")
                    .addClass("open")
                    .find(".dropdown-menu.inner")
                    .attr("aria-expanded", "true");
            }, 10);
        });

        // скрываем инпут и подставляем плейсхолдер по типу селекта
        $(".b-select__input input").blur(function () {
            $(this).parent().removeClass("js--open");

            var selectWrap = $(this).parent().next();
            var placeholder = selectWrap.find(".filter-option");

            if (this.value == "") {
                selectWrap.find("[data-toggle]").attr("title");
            } else {
                placeholder.html(this.value);
            }
        });

        $(".b-select__input input").on("keydown", function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                this.blur();

                $(this)
                    .parent()
                    .nextAll(".bootstrap-select")
                    .removeClass("open")
                    .find(".dropdown-menu.inner")
                    .attr("aria-expanded", "false");
            }
        });

        $(
            ".b-select--w-input .dropdown-toggle, .b-select--w-input .b-select__input"
        ).click(function () {
            var input = $(this)
                .closest(".b-select--w-input")
                .find(".b-select__input input");

            if (!$(this).closest(".bootstrap-select").hasClass("open")) {
                setTimeout(function () {
                    input.focus();
                }, 10);
            }
        });
    }, 500);
});

$(document).ready(function () {
    $(".table-orders__btn-more").click(function () {
        $(this)
            .toggleClass("js--toggled")
            .closest(".table-orders__row")
            .find(".table-orders__more")
            .slideToggle();
    });
    $(".table-orders__btn-time").click(function () {
        $(this)
            .closest(".table-orders__row")
            .find(".table-orders__time")
            .slideToggle();
    });
});

(function () {
    var selectCollapse = document.querySelectorAll("[data-select-collapse]");

    for (var i = 0; i < selectCollapse.length; i++) {
        initCollapseSelect(selectCollapse[i]);
    }

    function initCollapseSelect(container) {
        var headers,
            model = [],
            prevGroupId = null,
            $select = $(container).find("select");

        $select.on("loaded.bs.select", function () {
            init();
        });

        function init() {
            headers = container.querySelectorAll(".dropdown-header");
            for (var i = 0; i < headers.length; i++) {
                var group = {},
                    groupId = headers[i].getAttribute("data-optgroup");

                group.isShow = false;
                group.header = headers[i];
                group.items = container.querySelectorAll(
                    '[data-optgroup="' + groupId + '"]'
                );

                headers[i].addEventListener("click", onToggle);

                model.push(group);
            }
        }

        function onToggle() {
            var groupIdString = this.getAttribute("data-optgroup"),
                // В селекте индексы начинаются с 1
                groupId = parseInt(groupIdString, 10) - 1;

            if (model[groupId].isShow) {
                hideGroup(groupId);
                prevGroupId = null;
            } else {
                showGroup(groupId);
                if (prevGroupId !== null) {
                    hideGroup(prevGroupId);
                }
                prevGroupId = groupId;
            }
        }

        function showGroup(groupId) {
            model[groupId].isShow = true;
            for (var i = 0; i < model[groupId].items.length; i++) {
                model[groupId].items[i].classList.add("is-show");
            }
        }
        function hideGroup(groupId) {
            model[groupId].isShow = false;
            for (var i = 0; i < model[groupId].items.length; i++) {
                model[groupId].items[i].classList.remove("is-show");
            }
        }
    }
})();

(function () {
    var DropInput = function (container) {
        if (!container) {
            return;
        }

        var input = container.querySelector("input"),
            select = container.querySelector("select"),
            focusOutTimer;

        function onChange() {
            input.value = this.value;
            $(input).trigger("focusin");
            $(input).trigger("focusout");
        }

        function onFocusIs() {
            clearTimeout(focusOutTimer);
            container.classList.add("is-open");
        }
        function onFocusOut(event) {
            focusOutTimer = setTimeout(function () {
                container.classList.remove("is-open");
            }, 120);
        }

        input.addEventListener("focusin", onFocusIs);
        input.addEventListener("focusout", onFocusOut);
        if (select) select.addEventListener("change", onChange);
    };

    var dropInputs = document.querySelectorAll("[data-drop-input]");

    for (var i = 0; i < dropInputs.length; i++) {
        new DropInput(dropInputs[i]);
    }
})();

(function () {
    $(document).on("click", ".js-clear-input", function (event) {
        event.preventDefault();
        this.parentElement.parentElement.querySelector("input").value = "";
    });
    $(document)
        .on("mouseenter", ".js-show-password", function (event) {
            this.parentElement.parentElement.querySelector("input").type =
                "text";
        })
        .on("mouseleave", ".js-show-password", function (event) {
            this.parentElement.parentElement.querySelector("input").type =
                "password";
        });
})();

(function () {
    $(".accordion-body").on("shown.bs.collapse", function () {
        if ($(this)[0].querySelector("[data-shown-slider]")) {
            [].forEach.call(
                $(this)[0].querySelectorAll("[data-shown-slider]"),
                function (slider) {
                    slider.swiper.update();
                }
            );
        }
        if ($(this)[0].querySelector(".js-row-hight-container")) {
            setCellHeight($(this)[0]);
        }
        if ($(this)[0].querySelector(".js-scroll-container")) {
            setScrollPositionEnd($(this)[0]);
        }
    });
})();

(function () {
    const fixRow = document.querySelectorAll(".js-row-account");

    [].forEach.call(fixRow, function (item) {
        const check = item.querySelector(".js-account-check"),
            fixCheck = item.querySelector(".js-account-fix-check"),
            pennyInput = item.querySelector(".js-entity-penny"),
            pennySum = item.querySelector(".js-entity-penny-sum"),
            fixInput = item.querySelector(".js-account-input");

        if (check && fixCheck && fixInput) {
            check.addEventListener("click", function () {
                if (check.checked) {
                    fixInput.setAttribute("contenteditable", "true");
                    fixCheck.checked = true;
                    fixInput.focus();
                } else {
                    fixInput.removeAttribute("contenteditable");
                    fixCheck.checked = false;
                }
            });
            fixCheck.addEventListener("click", function () {
                if (fixCheck.checked) {
                    fixInput.setAttribute("contenteditable", "true");
                    check.checked = true;
                    fixInput.focus();
                } else {
                    fixInput.removeAttribute("contenteditable");
                    check.checked = false;
                }
            });
        }
        if (pennyInput && fixInput) {
            pennyInput.addEventListener("change", function () {
                const totalSum = fixInput.textContent.replace(/\s/g, "");
                const pennySumValue = pennySum.textContent.replace(/\s/g, "");
                if (pennyInput.checked) {
                    fixInput.textContent = +totalSum + +pennySumValue;
                } else {
                    fixInput.textContent = +totalSum - +pennySumValue;
                }
            });
        }
    });
})();

// избранное и кнопка прочитанное у уведомлений
function chosenMessage() {
    const starIcons = document.querySelectorAll(".js-star-icon");
    const messageContainer = document.querySelectorAll(".js-message-container");

    if (starIcons.length > 0) {
        [].forEach.call(starIcons, function (starIcon) {
            starIcon.addEventListener("click", function () {
                starIcon.classList.toggle("is-active");
            });
        });
    }

    if (messageContainer.length === 0) {
        return;
    }

    [].forEach.call(messageContainer, function (message) {
        const readMessageBtn = message.querySelector(".js-message-read");

        readMessageBtn.addEventListener("click", function () {
            readMessageBtn.classList.toggle("is-read");

            if (readMessageBtn.classList.contains("is-read")) {
                message.classList.add("is-read");
            } else {
                message.classList.remove("is-read");
            }
        });
    });
}
chosenMessage();

//  переключение даты у уведомлений
function changeDateTabs() {
    const messagesContainerArr = document.querySelectorAll(
        ".js-messages-container"
    );
    if (messagesContainerArr.length === 0) {
        return;
    }

    [].forEach.call(messagesContainerArr, function (messageContainer) {
        const dateBtns = messageContainer.querySelectorAll(".js-message-dates");
        if (dateBtns.length === 0) {
            return;
        }

        [].forEach.call(dateBtns, function (dateBtn) {
            dateBtn.addEventListener("click", function () {
                [].forEach.call(dateBtns, function (dateBtn) {
                    dateBtn.classList.remove("is-active");
                });
                dateBtn.classList.add("is-active");
                const urlPhp = dateBtn.getAttribute("data-button-url");

                otherElements(messageContainer, ".js-message-wrapper", urlPhp);
            });
        });
    });
}
changeDateTabs();

function otherElements(parentSelector, messageWrapperSelector, url) {
    const messageWrapper = parentSelector.querySelector(messageWrapperSelector);
    const loaderBlock = document.querySelector(".js-load-message");
    if (loaderBlock) {
        loaderBlock.style.display = "block";
    }

    getOtherElements(messageWrapper, url, loaderBlock);
    if (parentSelector.querySelector(".js-message-slider")) {
        parentSelector.querySelector(".js-message-slider").swiper.update();
    }
}

const getOtherElements = function (inner, url, loaderBlock) {
    $.ajax({
        url: url,
        method: "get",
        dataType: "json",
        success: function (response) {
            if (true) {
                if (loaderBlock) {
                    setTimeout(function () {
                        loaderBlock.style.display = "none";
                    }, 600);
                }
                setTimeout(function () {
                    inner.innerHTML = response.data;
                    chosenMessage();
                }, 600);
            } else {
            }
        },
        error: function (response) {
            console.error(response);
            inner.innerHTML = "Что-то пошло не так";
        },
    });
};

// скролл к определенному блоку
(function () {
    const scrollToElements = document.querySelectorAll("[data-scroll-to-el]");
    if (!scrollToElements) returns;
    [].forEach.call(scrollToElements, function (el) {
        el.addEventListener("click", function (e) {
            e.preventDefault();
            target = this.getAttribute("href");
            const targetBlock = document.querySelector(target);
            if (targetBlock) targetBlock.scrollIntoView({ behavior: "smooth" });
        });
    });
})();

// скролл к определенному блоку с коллапсом
(function () {
    function scrollTo() {
        const arrTrigger = document.querySelectorAll("[data-scroll-to]");
        if (arrTrigger.length > 0) {
            [].forEach.call(arrTrigger, function (btn) {
                btn.addEventListener("click", function () {
                    let target;
                    const scrollClassContainer = btn.closest(".js-scroll-here");
                    target = this.getAttribute("href");
                    const targetBlock = document.querySelector(target);

                    if (!targetBlock.classList.contains("in")) {
                        setTimeout(function () {
                            if (
                                scrollClassContainer != undefined &&
                                window.matchMedia("(min-width: 1025px)").matches
                            ) {
                                scrollClassContainer.scrollIntoView({
                                    behavior: "smooth",
                                });
                            } else {
                                targetBlock.scrollIntoView({
                                    behavior: "smooth",
                                });
                            }
                        }, 250);
                    }
                });
            });
        }
    }

    scrollTo();

    $("body").on("click", '[data-toggle="collapse"]', function () {
        scrollTo();
        if (this.getAttribute("aria-expanded") == "true") {
            const parent = this.closest(".js-collapse-toggle-group");
            if (!parent) return;
            const defaultToggle = parent.querySelector(
                ".js-collapse-toggle-all"
            );
            if (defaultToggle) defaultToggle.classList.add("collapsed");
        }
    });
})();

// замена кнопки на крестик
(function () {
    function changeBtnClick() {
        const arrTrigger = document.querySelectorAll(".js-close-toggle");
        const arrTriggerInBlock = document.querySelectorAll(
            ".js-close-toggle-in"
        );
        if (arrTrigger.length > 0) {
            [].forEach.call(arrTrigger, function (btn) {
                btn.addEventListener("click", function () {
                    changeBtn(btn);
                });
            });
        }
        if (arrTriggerInBlock.length > 0) {
            [].forEach.call(arrTriggerInBlock, function (btn) {
                btn.addEventListener("click", function () {
                    const href = btn.getAttribute("href");
                    const btnCloseTrigger = document.querySelector(
                        '[href="' + href + '"'
                    );
                    changeBtn(btnCloseTrigger);
                });
            });
        }
    }

    function changeBtn(button) {
        setTimeout(function () {
            if (!button.classList.contains("collapsed")) {
                button.classList.remove("button-icon--th-primary");
                button.classList.add("button-icon--th-close");
                button.innerHTML = '<i class="icon icon--close"></i>';
            } else {
                button.classList.remove("button-icon--th-close");
                button.classList.add("button-icon--th-primary");
                button.innerHTML = '<i class="icon icon--help"></i>';
            }
        }, 100);
    }

    changeBtnClick();

    $("body").on("click", '[data-toggle="collapse"]', function () {
        changeBtnClick();
    });
})();

// подгрузка данных при скролле
document.addEventListener("DOMContentLoaded", function () {
    function addDataTestimony(selectorContent) {
        var btnMore = document.querySelector(".js-btn-testimony");
        var contentContainer = document.querySelector(selectorContent);
        var urlPhp;

        if (btnMore) {
            urlPhp = btnMore.getAttribute("data-button-url");
        }

        var observer = new IntersectionObserver(
            function (entries) {
                [].forEach.call(entries, function (entry) {
                    var loaderBlock =
                        document.querySelector(".js-load-testimony");
                    if (loaderBlock) {
                        loaderBlock.style.display = "block";
                    }
                    getResource(urlPhp);
                });
            },
            {
                threshold: 1,
            }
        );

        if (btnMore) {
            observer.observe(btnMore);
        }

        var getResource = function (url) {
            $.ajax({
                url: url,
                method: "get",
                dataType: "json",
                success: function (response) {
                    if (true) {
                        var datas = response.data;
                        var btnMoreFooter = document.querySelector(
                            ".js-btn-testimony-block"
                        );

                        contentContainer.removeChild(btnMoreFooter);

                        const newElement = document.createElement("div");
                        newElement.innerHTML = datas;
                        contentContainer.appendChild(newElement);

                        if (response.button) {
                            contentContainer.appendChild(btnMoreFooter);
                        }

                        var loaderBlock =
                            document.querySelector(".js-load-testimony");
                        if (loaderBlock) {
                            setTimeout(function () {
                                loaderBlock.style.display = "none";
                            }, 600);
                        }
                        datesSlider();
                        changeDateTabs();
                        datesEntitySlider();
                    } else {
                    }
                },
                error: function (response) {
                    console.error(response);
                    inner.innerHTML = "Что-то пошло не так";
                },
            });
        };
    }

    addDataTestimony(".js-testimony-list");
});

// collapsed у чекбокс
(function () {
    const arrTriggerContainer = document.querySelectorAll(
        ".js-checkox-container"
    );
    if (arrTriggerContainer.length > 0) {
        [].forEach.call(arrTriggerContainer, function (container) {
            const arrTrigger = container.querySelectorAll(
                "[data-checkbox-toggle]"
            );
            var collapseTrigger;
            if (arrTrigger.length > 0) {
                var collapseBlock;
                [].forEach.call(arrTrigger, function (trigger) {
                    var id = trigger.getAttribute("data-checkbox-toggle");
                    if (id) {
                        collapseTrigger = trigger;
                        collapseBlock = container.querySelector(id);
                    }
                });
                [].forEach.call(arrTrigger, function (trigger) {
                    trigger.addEventListener("change", function () {
                        var otherToggle = container.querySelector(
                            "[data-checkbox-toggle-main]"
                        );
                        if (otherToggle) {
                            if (otherToggle.checked) {
                                if (collapseBlock) {
                                    collapseBlock.classList.add("in");

                                    if (
                                        otherToggle.classList.contains(
                                            "js-disable-btns"
                                        )
                                    ) {
                                        disableBtn();
                                    }
                                }
                            } else {
                                if (collapseBlock) {
                                    collapseBlock.classList.remove("in");
                                    if (
                                        otherToggle.classList.contains(
                                            "js-disable-btns"
                                        )
                                    ) {
                                        enableBtn();
                                    }
                                }
                            }
                        }
                    });
                });
            }

            function disableBtn() {
                const inputsConfirmation = container.querySelectorAll(
                    ".js-edm-confirmation"
                );
                if (inputsConfirmation.length > 0) {
                    [].forEach.call(inputsConfirmation, function (input) {
                        input.classList.add("is-disable");
                    });
                }

                const poisonCode =
                    container.querySelectorAll(".js-poison-code");
                if (poisonCode.length > 0) {
                    if (poisonCode.length > 0) {
                        [].forEach.call(poisonCode, function (trigger) {
                            trigger.classList.add("is-disable");
                        });
                    }
                }
            }

            function enableBtn() {
                const inputsConfirmation = container.querySelectorAll(
                    ".js-edm-confirmation"
                );
                if (inputsConfirmation.length > 0) {
                    [].forEach.call(inputsConfirmation, function (input) {
                        input.classList.remove("is-disable");
                    });
                }

                const poisonCode =
                    container.querySelectorAll(".js-poison-code");
                if (poisonCode.length > 0) {
                    if (poisonCode.length > 0) {
                        [].forEach.call(poisonCode, function (trigger) {
                            if (
                                !trigger.classList.contains(
                                    "undisabled-after-review"
                                )
                            ) {
                                trigger.classList.remove("is-disable");
                            }
                        });
                    }
                }
            }
        });
    }
})();

// таймер
function playTimer(timer) {
    const fullCircle = 283;
    const startTime = 300;
    let timePassed = 0;
    let timeLeft = startTime;
    const timerCircle = timer.querySelector(".js-timer-circle");
    const timerCount = timer.querySelector(".js-timer-count");

    timer.classList.add("is-show");
    timerCircle.setAttribute("stroke-dasharray", fullCircle);
    timerCount.textContent = timeLeft;
    let timerInterval = null;
    startTimer();

    function setCircleDasharray() {
        const circleDasharray =
            (calculateTimeFraction() * fullCircle).toFixed(0) + " 283";
        timerCircle.setAttribute("stroke-dasharray", circleDasharray);
    }

    function startTimer() {
        timerInterval = setInterval(function () {
            timePassed = timePassed += 1;
            timeLeft = startTime - timePassed;
            timerCount.innerHTML = timeLeft;

            if (timeLeft === 0) {
                onTimesUp();
                showBtnAgain();
            }
            setCircleDasharray();
        }, 1000);
    }

    function calculateTimeFraction() {
        const rawTimeFraction = timeLeft / startTime;
        return rawTimeFraction - (1 / startTime) * (1 - rawTimeFraction);
    }

    function onTimesUp() {
        clearInterval(timerInterval);
    }

    function showBtnAgain() {
        timer.classList.remove("is-show");
        const timerContainer = timer.closest(".js-timer-container");
        const timerContainerRemoveProfile = timer.closest(".js-remove-profile");
        if (timerContainer) {
            const poisonCodeAgain = timerContainer.querySelector(
                ".js-poison-code-again"
            );
            const connectEdm = timerContainer.querySelector(".js-connect-edm");
            const poisonCodeSubmit = timerContainer.querySelector(
                ".js-poison-code-submit"
            );
            if (poisonCodeAgain) poisonCodeAgain.style.display = "block";
            if (connectEdm) connectEdm.style.display = "none";
            if (poisonCodeSubmit) poisonCodeSubmit.style.display = "none";
        }
        if (timerContainerRemoveProfile) {
            const profileCodeAgain = timerContainerRemoveProfile.querySelector(
                ".js-profile-code-again"
            );
            if (profileCodeAgain)
                profileCodeAgain.style.display = "inline-block";
        }
    }
}

// отправка кода (обмен ЭДО)
(function () {
    const arrTriggerContainer = document.querySelectorAll(
        ".js-timer-container"
    );
    if (arrTriggerContainer.length > 0) {
        [].forEach.call(arrTriggerContainer, function (container) {
            const poisonCode = container.querySelectorAll(".js-poison-code");
            const connectEdm = container.querySelector(".js-connect-edm");
            const connectEdmInput = container.querySelector(
                ".js-connect-edm-input"
            );
            const poisonCodeSubmit = container.querySelector(
                ".js-poison-code-submit"
            );
            const inputCode = container.querySelector(".js-input-code");
            const timer = container.querySelector(".js-timer");
            const hiddenBlocks = container.querySelectorAll(
                ".js-timer-hidden-block"
            );
            const visibleBlocks = container.querySelectorAll(
                ".js-timer-visible-block"
            );
            if (poisonCode.length > 0) {
                [].forEach.call(poisonCode, function (trigger) {
                    trigger.addEventListener("click", function () {
                        playTimer(timer);
                        if (connectEdm) connectEdm.style.display = "";
                        if (poisonCodeSubmit)
                            poisonCodeSubmit.style.display = "";
                        if (inputCode) inputCode.style.display = "block";
                        trigger.style.display = "none";

                        hiddenBlocks.forEach((item) => {
                            item.style.display = "none";
                        });

                        visibleBlocks.forEach((item) => {
                            item.style.display = "";
                        });
                    });
                });

                if (connectEdmInput) {
                    connectEdmInput.addEventListener("change", () => {
                        if (connectEdmInput.value.length > 0) {
                            connectEdm.classList.remove("is-disable");
                        }
                    });
                }
            }
        });
    }
})();

// удаление профиля
(function () {
    const arrTriggerContainer = document.querySelectorAll(".js-remove-profile");
    [].forEach.call(arrTriggerContainer, function (container) {
        const triggers = container.querySelectorAll(
            ".js-remove-profile-trigger"
        );
        const btnNext = container.querySelector(".js-remove-profile-next");
        const inputCode = container.querySelector(".js-input-code");
        const inputContact = container.querySelector(".js-input-contact");
        const timer = container.querySelector(".js-timer");
        const profileCodeAgain = container.querySelector(
            ".js-profile-code-again"
        );
        const removeProfileTrigger = container.querySelector(
            ".js-remove-profile-remove"
        );
        const removeButtonHiddenWidthTimer = container.querySelector(
            ".js-profile-remove-button"
        );
        [].forEach.call(triggers, function (trigger) {
            trigger.addEventListener("click", function () {
                const container = trigger.closest(
                    ".js-remove-profile-container"
                );
                let message;
                if (container) {
                    message = container.querySelector(
                        ".js-remove-profile-message"
                    );
                    if (message) message.style.display = "block";
                }
                playTimer(timer);
                if (btnNext) btnNext.style.display = "inline-block";
                if (inputCode) inputCode.style.display = "block";
                if (inputContact) inputContact.style.display = "none";
                trigger.style.display = "none";
                if (removeButtonHiddenWidthTimer)
                    removeButtonHiddenWidthTimer.style.display = "none";
            });
        });
        if (btnNext) {
            btnNext.addEventListener("click", function () {
                if (!btnNext.classList.contains("not-hide"))
                    btnNext.style.display = "none";
                profileCodeAgain.style.display = "none";
                timer.style.display = "none";
                if (removeProfileTrigger)
                    removeProfileTrigger.style.display = "inline-flex";
            });
        }
    });
})();

//функция перекрашивания непрочитанных сообщений после раскрытия
function markAsRead(targetElement) {
    const parentContainer = targetElement.closest(".js-request-list__item");

    if (
        parentContainer &&
        parentContainer.classList.contains("request-list__item--dark")
    ) {
        parentContainer.classList.remove("request-list__item--dark");
    }
}
//функция удаления темного фона у непрочитанных сообщений
(function () {
    document.addEventListener("DOMContentLoaded", function () {
        const btnReadMore = document.querySelector(".js-btn--read-all");
        if (btnReadMore) {
            btnReadMore.addEventListener("click", function () {
                const requestListItems = document.querySelectorAll(
                    ".js-request-list__item"
                );
                if (requestListItems.length > 0) {
                    requestListItems.forEach(function (item) {
                        if (
                            item.classList.contains("request-list__item--dark")
                        ) {
                            item.classList.remove("request-list__item--dark");
                        }
                    });
                }
            });
        }
    });
})();

// Переключение между вкладками Лицевые счета
(function () {
    const arrCollapseContainer = document.querySelectorAll(
        ".js-collapse-toggle-container"
    );

    [].forEach.call(arrCollapseContainer, function (container) {
        const arrCollapseToggle = container.querySelectorAll(
            ".js-collapse-toggle"
        );
        const arrCollapseGroupToggle = container.querySelectorAll(
            ".js-collapse-toggle-all"
        );

        [].forEach.call(arrCollapseToggle, function (toggle) {
            toggle.addEventListener("click", function (e) {
                setTimeout(function () {
                    $(container)
                        .find(".js-collapse-toggle")
                        .each(function () {
                            var target = $(this).attr("href");
                            $(target).collapse("hide");
                        });
                    [].forEach.call(
                        arrCollapseGroupToggle,
                        function (toggleAll) {
                            toggleAll.classList.add("collapsed");
                        }
                    );
                    markAsRead(toggle);
                    const parent = toggle.closest(".js-collapse-toggle-group");
                    if (!parent) return;
                    const defaultToggle = parent.querySelector(
                        ".js-collapse-toggle-all"
                    );
                    if (toggle.classList.contains("collapsed")) {
                        if (defaultToggle)
                            defaultToggle.classList.add("collapsed");
                    } else {
                        if (defaultToggle) {
                            defaultToggle.classList.remove("collapsed");
                        }
                    }

                    const activeToggleCheckboxes = container.querySelectorAll(
                        "[data-toggle-account]:checked"
                    );
                    if (activeToggleCheckboxes.length > 1) {
                        [].forEach.call(
                            activeToggleCheckboxes,
                            function (checkbox) {
                                checkbox.checked = false;
                            }
                        );
                        const togglePayblock =
                            $(".js-entity-account").find("[data-toggle-body]");
                        if (togglePayblock) togglePayblock.collapse("hide");
                    }
                }, 10);
            });
        });

        [].forEach.call(arrCollapseGroupToggle, function (toggle) {
            toggle.addEventListener("click", function (e) {
                e.preventDefault();
                setTimeout(function () {
                    $(container)
                        .find(".js-collapse-toggle")
                        .each(function () {
                            var target = $(this).attr("href");
                            $(target).collapse("hide");
                        });

                    [].forEach.call(
                        arrCollapseGroupToggle,
                        function (toggleAll) {
                            if (toggleAll !== toggle) {
                                toggleAll.classList.add("collapsed");
                            }
                        }
                    );

                    if (!toggle.classList.contains("collapsed")) {
                        toggle.classList.add("collapsed");
                    } else {
                        const parent = toggle.closest(
                            ".js-collapse-toggle-group"
                        );
                        if (!parent) return;
                        const collapseTarget = $(toggle.hash);
                        $(collapseTarget).collapse("show");
                        toggle.classList.remove("collapsed");
                    }

                    const activeToggleCheckboxes = container.querySelectorAll(
                        "[data-toggle-account]:checked"
                    );
                    if (activeToggleCheckboxes.length > 1) {
                        [].forEach.call(
                            activeToggleCheckboxes,
                            function (checkbox) {
                                checkbox.checked = false;
                            }
                        );
                        const togglePayblock =
                            $(".js-entity-account").find("[data-toggle-body]");
                        if (togglePayblock) togglePayblock.collapse("hide");
                    }
                });
            });
        });

        const arrCollapseToggleSecond = container.querySelectorAll(
            ".js-collapse-toggle-second"
        );

        [].forEach.call(arrCollapseToggleSecond, function (toggle) {
            toggle.addEventListener("click", function (e) {
                setTimeout(function () {
                    $(container)
                        .find(".js-collapse-toggle-second")
                        .each(function () {
                            var target = $(this).attr("href");
                            $(target).collapse("hide");
                        });
                }, 10);
            });
        });
    });
})();

// перенести поля формы
(function () {
    const copyFormContainers = document.querySelectorAll(
        ".js-copy-inputs-container"
    );

    [].forEach.call(copyFormContainers, function (container) {
        const checkbox = container.querySelector(".js-copy-inputs-checkbox");
        const mainInputs = container.querySelectorAll("[data-copy-input-main]");
        const secondaryInputs = container.querySelectorAll(
            "[data-copy-input-second]"
        );

        checkbox.addEventListener("change", function () {
            if (checkbox.checked) {
                copyInputs();
            }
        });

        function copyInputs() {
            [].forEach.call(mainInputs, function (mainInput) {
                if (mainInput.classList.contains("selectpicker")) {
                    const optionsMainInput =
                        mainInput.querySelectorAll("option");
                    const attrMainInput = mainInput.getAttribute(
                        "data-copy-input-main"
                    );
                    const activeOptionValue =
                        optionsMainInput[mainInput.selectedIndex].value;

                    [].forEach.call(secondaryInputs, function (secondaryInput) {
                        const attrSecondaryInput = secondaryInput.getAttribute(
                            "data-copy-input-second"
                        );

                        if (attrSecondaryInput === attrMainInput) {
                            $(secondaryInput).selectpicker(
                                "val",
                                activeOptionValue
                            );
                        }
                    });
                } else if (
                    mainInput.type === "text" ||
                    mainInput.type === "number"
                ) {
                    const attrMainInput = mainInput.getAttribute(
                        "data-copy-input-main"
                    );
                    const valueMainInput = mainInput.value;

                    [].forEach.call(secondaryInputs, function (secondaryInput) {
                        const attrSecondaryInput = secondaryInput.getAttribute(
                            "data-copy-input-second"
                        );

                        if (attrSecondaryInput === attrMainInput) {
                            secondaryInput.value = valueMainInput;
                        }
                    });
                }
            });
        }
    });
})();

// скрыть блок, если поля повторяются
(function () {
    const copyFormContainers = document.querySelectorAll(
        ".js-hidden-block-container"
    );

    [].forEach.call(copyFormContainers, function (container) {
        const checkbox = container.querySelector(".js-hidden-block-check");
        const blockInputs = container.querySelector(".js-hidden-block");

        checkbox.addEventListener("change", function () {
            if (checkbox.checked) {
                blockInputs.classList.add("is-hide");
            } else {
                blockInputs.classList.remove("is-hide");
            }
        });
    });
})();

// Переключение между вкладками Лицевые счета
(function () {
    const connectionContainer = document.querySelector(".js-connection-toggle");
    if (!connectionContainer) {
        return;
    }

    const connectionButtons = connectionContainer.querySelectorAll(
        "[data-connection-button]"
    );
    const connectionSliders = connectionContainer.querySelectorAll(
        "[data-connection-slider]"
    );
    const sliderOverlay =
        connectionContainer.querySelector(".js-slider-overlay");

    [].forEach.call(connectionButtons, function (toggle, i) {
        toggle.addEventListener("click", function (e) {
            e.preventDefault();
            sliderOverlay.classList.add("is-show");
            setTimeout(function () {
                removeActiveClass(connectionButtons);
                removeActiveClass(connectionSliders);

                toggle.classList.add("is-active");
                connectionSliders[i].classList.add("is-active");
                connectionSlider();
            }, 200);
            setTimeout(function () {
                sliderOverlay.classList.remove("is-show");
            }, 250);
        });
    });

    function removeActiveClass(selector) {
        [].forEach.call(selector, function (item) {
            item.classList.remove("is-active");
        });
    }

    window.addEventListener("resize", function () {
        connectionSlider();
        data - file - input;
    });
})();

// продублировать блок, при клике на кнопку
(function () {
    const copyBlockContainers = document.querySelectorAll(
        ".js-copy-block-container"
    );

    [].forEach.call(copyBlockContainers, function (container) {
        let counter = 1;
        const itemsInner = container.querySelector(".js-copy-block-inner");
        const itemBlock = container.querySelector(".js-copy-block-item");
        const trigger = container.querySelector(".js-copy-block-button");
        const triggerRemove = container.querySelector(
            ".js-copy-block-button-remove"
        );

        const counterContainer = container.querySelector(".js-title-counter");
        let counterAttr;
        if (counterContainer) counterAttr = counterContainer.dataset.counter;
        if (counterAttr != "") counter = counterAttr;

        trigger.addEventListener("click", function (e) {
            e.preventDefault();
            counter++;

            const copyItem = itemBlock.cloneNode(true);

            const counterSpan = copyItem.querySelector(".js-title-counter");
            if (counterSpan) counterSpan.textContent = counter;
            copyItem.style.display = "";

            const deleteButton = copyItem.querySelector(".js-delete-item");
            if (deleteButton) deleteButton.style.display = "block";

            if (itemsInner) itemsInner.appendChild(copyItem);

            const inputs = copyItem.querySelectorAll("input");
            [].forEach.call(inputs, function (input) {
                input.value = "";
            });

            if (deleteButton)
                deleteButton.addEventListener("click", function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    counter--;
                    itemsInner.removeChild(copyItem);

                    if (counter > 9) {
                        trigger.style.display = "none";
                    } else {
                        trigger.style.display = "flex";
                    }
                });

            // инитим файлы
            const docLoaders = copyItem.querySelectorAll(
                "[data-file-loader].js-new-file-loader"
            );
            for (var i = 0; i < docLoaders.length; i++) {
                const item = docLoaders[i];

                if (i > 0) {
                    item.classList.remove("js-new-file-loader");
                }

                const fileLoader = new FileUploadManager(item, {});
                fileLoader.reset();
            }

            // инитим календари
            const calendarsInputs = copyItem.querySelectorAll(
                ".js-period-picker, .js-period-picker-right, .js-period-picker-days, .js-period-picker-days-year"
            );
            if (calendarsInputs.length) {
                initCalendars(copyItem);
            }

            // инитим выпадающие списки
            const bootstrapSelect =
                copyItem.querySelectorAll(".bootstrap-select");
            [].forEach.call(bootstrapSelect, function (selectContainer) {
                const select = selectContainer.querySelector("select");
                const parent = selectContainer.parentElement;
                if (select) {
                    parent.innerHTML = "";
                    parent.appendChild(select);
                }
            });
            $(".selectpicker", copyItem).selectpicker("refresh");

            // прятать/показывать блок в зависимости от выбранного option
            const showBlockTriggers = copyItem.querySelectorAll(
                ".js-set-visible-container"
            );
            if (showBlockTriggers.length) {
                setVisibleInput();
            }

            // переключатель да/нет - инит
            const switchTriggers = copyItem.querySelectorAll(
                "[data-toggle-switch-inputs]"
            );
            if (switchTriggers.length) {
                initSwitches(copyItem);
            }

            if (counter > 9) {
                trigger.style.display = "none";
            } else {
                trigger.style.display = "flex";
            }

            if (triggerRemove) triggerRemove.style.display = "";
        });

        if (triggerRemove) {
            triggerRemove.addEventListener("click", function (e) {
                e.preventDefault();
                counter--;
                const newBlocks = container.querySelectorAll(
                    ".js-copy-block-item"
                );
                if (newBlocks.length > 1) {
                    newBlocks[newBlocks.length - 1].remove();
                }
            });
        }
    });
})();

// не скроллить слайдер, если событие на блоке со скроллом внутри слайдера
(function () {
    const arrScrollContainer = document.querySelectorAll(".js-scoll-block");
    if (!arrScrollContainer) return;

    if (window.matchMedia("(max-width: 1024px)").matches) {
        [].forEach.call(arrScrollContainer, function (container) {
            container.addEventListener("mouseover", function (e) {
                e.stopPropagation();
            });

            container.addEventListener("touchstart", function (e) {
                e.stopPropagation();
            });
        });
    }
})();

// разблокировать блок, если выбран чекбокс
(function () {
    const radioInputs = document.querySelectorAll("[data-check-input]");

    [].forEach.call(radioInputs, function (input) {
        input.addEventListener("change", function (e) {
            const attr = input.dataset.checkInput;
            const blocks = document.querySelectorAll(
                '[data-check-container="' + attr + '"]'
            );

            [].forEach.call(blocks, function (block) {
                input.checked
                    ? block.classList.add("is-show")
                    : block.classList.remove("is-show");
            });
        });
    });
})();

// показать блок, если выбран option
window.addEventListener("load", function () {
    (function () {
        const selects = document.querySelectorAll("[data-show-block-trigger]");

        [].forEach.call(selects, function (select) {
            const dropdow = select.querySelector(".dropdown-menu.inner");
            if (dropdow)
                dropdow.addEventListener("click", function (e) {
                    const trigger = e.target;
                    const attr = select.dataset.showBlockTrigger;
                    const blocks = document.querySelectorAll(
                        '[data-show-block-container="' + attr + '"]'
                    );

                    [].forEach.call(blocks, function (block) {
                        trigger.closest(".js-show-block-trigger")
                            ? block.classList.remove("is-hide")
                            : block.classList.add("is-hide");
                    });
                });
        });
    })();
});

// скопировать блок на мобилке
(function () {
    const copyBlockContainers = document.querySelectorAll(
        ".js-copy-mobile-container"
    );

    [].forEach.call(copyBlockContainers, function (container) {
        const itemsInner = container.querySelectorAll(".js-copy-mobile-inner");
        const itemBlock = container.querySelector(".js-copy-mobile-item");
        if (!itemsInner || !itemBlock) return;
        function copyBlock() {
            if (window.matchMedia("(max-width: 1099px)").matches) {
                itemsInner.forEach((inner) => {
                    const copyItem = itemBlock.cloneNode(true);
                    copyItem.style.display = "";

                    inner.innerHTML = "";
                    if (inner) inner.appendChild(copyItem);

                    datesEntitySlider();
                    $(".js-form-payment").on("click", function (event) {
                        event.preventDefault();
                        var self = $(this);

                        self.addClass("is-overlay");
                        $(this).find(
                            $(".js-form-payment-doc").addClass("is-hide")
                        );

                        setTimeout(function () {
                            self.removeClass("is-overlay");
                            if (self.hasClass("is-doc")) {
                                self.removeClass("is-disable");
                                self.removeClass("js-form-payment");
                                self.off("click");
                            }
                            $(".js-form-payment-doc").removeClass("is-hide");
                        }, 1500);
                    });
                });
            } else {
                [].forEach.call(itemsInner, function (inner) {
                    inner.innerHTML = "";
                });
            }
        }
        copyBlock();
    });
})();

// ссылка внутри селекта
(function () {
    const selectsWithLink = document.querySelectorAll(
        ".js-select-with-link-container"
    );

    [].forEach.call(selectsWithLink, function (container) {
        const select = container.querySelector(".js-select-with-link");
        container.addEventListener("click", function (e) {
            const target = e.target.closest(".js-select-link");
            if (
                target &&
                !target.classList.contains("js-select-link-not-follow")
            ) {
                window.location = select.value;
            }
        });
    });
})();

// ссылка внутри селекта inline-block
(function () {
    const selectsWithLinkInlineBlock = Array.from(
        document.querySelectorAll(".js-select-link-inline-block")
    );
    const parent = selectsWithLinkInlineBlock[0]?.closest(
        ".js-select-with-link-container"
    );

    if (parent) {
        const mo = new MutationObserver(function (mutationsList, observer) {
            for (const mutation of mutationsList) {
                if (mutation.type === "childList") {
                    const _selectsWithLinkInlineBlock = Array.from(
                        mutation.target.querySelectorAll(
                            ".js-select-link-inline-block"
                        )
                    );
                    _selectsWithLinkInlineBlock.forEach((el) => {
                        const li = el.closest("li");

                        if (li) {
                            li.classList.add("select-link-inline-block-parent");
                        }
                    });
                }
            }
        });

        mo.observe(parent, { childList: true });
    }
})();

// менять иконки карт оплаты
(function () {
    const selectsWithLink = document.querySelectorAll(
        ".js-select-cards-container"
    );

    [].forEach.call(selectsWithLink, function (container) {
        container.addEventListener("click", function (e) {
            const target = e.target.closest(".js-card-icon-trigger");
            if (!target) return;
            const payContainer = target.closest(".js-pay-container");

            if (payContainer) {
                const logoImage = payContainer.querySelector(".js-card-logo");
                const iconAttr = target.querySelector(".glyphicon");
                if (logoImage && iconAttr) {
                    const srcIcon = iconAttr.className.split(" ")[1];
                    if (srcIcon) logoImage.src = srcIcon;
                }
            }
        });
    });
})();

// переход по инпутам с помощью enter
(function () {
    const transitionInoutsContainers = document.querySelectorAll(
        ".js-transition-inputs-container"
    );

    [].forEach.call(transitionInoutsContainers, function (container) {
        const inputs = container.querySelectorAll(".js-transition-input");
        [].forEach.call(inputs, function (input, i) {
            input.addEventListener("keypress", function (e) {
                if (e.keyCode == 13) {
                    e.preventDefault();
                    if (i != inputs.length - 1) {
                        inputs[i + 1].focus();
                    } else {
                        inputs[i].blur();
                    }
                }
            });
        });
    });
})();

(function () {
    const buttonsChangeText = Array.from(
        document.querySelectorAll(".js-button-change-text")
    );

    if (!buttonsChangeText) return;

    [].forEach.call(buttonsChangeText, function (button) {
        const firstText = button.querySelector(".js-change-text-first");
        const secondText = button.querySelector(".js-change-text-second");

        if (secondText) secondText.style.display = "none";
        button.addEventListener("click", changeText);

        function changeText(e) {
            e.preventDefault();
            const container = button.closest(".js-show-text-container");
            if (secondText) secondText.style.display = "block";
            if (firstText) firstText.style.display = "none";
            button.classList.remove("js-button-change-text");

            if (container) {
                const messsages = document.querySelectorAll(".js-message-text");
                const buttonIndex = buttonsChangeText.indexOf(button);

                if (messsages && messsages[buttonIndex]) {
                    [].forEach.call(messsages, function (message) {
                        message.style.display = "none";
                    });
                    messsages[buttonIndex].style.display = "block";
                }
            }

            button.removeEventListener("click", changeText);
        }
    });
})();

// редактирование итоговой суммы
(function () {
    const textEditContainers = Array.from(
        document.querySelectorAll(".js-text-edit-container")
    );

    if (!textEditContainers) return;

    [].forEach.call(textEditContainers, function (container) {
        const trigger = container.querySelector(".js-text-edit-trigger");
        const input = container.querySelector(".js-text-edit-input");
        const removeButton = container.querySelector(".js-text-edit-remove");

        if (!input || !trigger) return;

        const inputValue = input.value;

        trigger.addEventListener("click", editText);
        if (removeButton) removeButton.addEventListener("click", disableText);

        function editText(e) {
            e.preventDefault();

            trigger.style.display = "none";
            removeButton.style.display = "block";

            input.removeAttribute("disabled");
            input.focus();
            input.selectionStart = input.value.length;
        }

        function disableText(e) {
            e.preventDefault();

            trigger.style.display = "block";
            removeButton.style.display = "none";

            input.value = inputValue;
            input.setAttribute("disabled", true);
        }
    });
})();

// изменение кнопки в зависиомтси от выбранного способа получения чека
(function () {
    const buttonChangeContainers = Array.from(
        document.querySelectorAll(".js-change-button-container")
    );

    if (!buttonChangeContainers) return;

    [].forEach.call(buttonChangeContainers, function (container) {
        const trigger = container.querySelector(".js-change-button-submit");
        const checkboxes = container.querySelectorAll(
            ".js-change-button-checkbox"
        );
        const oldButton = container.querySelector(".js-change-button-old");
        const newButton = container.querySelector(".js-change-button-new");

        if (!checkboxes || !trigger || !oldButton || !newButton) return;

        const buttonTextContainer = newButton.querySelector(
            ".js-change-button-text"
        );

        trigger.addEventListener("click", changeButton);

        function changeButton(e) {
            oldButton.style.display = "none";
            newButton.style.display = "block";
            let textButton;
            [].forEach.call(checkboxes, function (checkbox) {
                if (checkbox.checked && checkbox.dataset.typeGetWay) {
                    textButton = checkbox.dataset.typeGetWay;

                    if (checkbox.classList.contains("js-blue-text")) {
                        buttonTextContainer.classList.add(
                            "object__ctrl-down--secondary"
                        );
                        buttonTextContainer.classList.remove(
                            "object__ctrl-down--success"
                        );
                    } else {
                        buttonTextContainer.classList.add(
                            "object__ctrl-down--success"
                        );
                        buttonTextContainer.classList.remove(
                            "object__ctrl-down--secondary"
                        );
                    }
                }
            });
            buttonTextContainer.textContent = textButton;
        }
    });
})();

// показывать, либо скрывать блок, в зависимости от выбранного option
function setVisibleInput() {
    const selects = document.querySelectorAll(".js-set-visible-container");
    [].forEach.call(selects, function (select) {
        const dropdow = select.querySelector(".dropdown-menu.inner");
        if (dropdow)
            dropdow.addEventListener("click", function (e) {
                changeItems(e.target);
            });

        function changeItems(trigger) {
            const parent = trigger.closest(".js-set-visible-parent");
            if (!parent) return;

            const inputsByShow = parent.querySelectorAll("[data-show-block]");
            if (trigger.dataset.showBlockTrigger) {
                const valueOption = trigger.dataset.showBlockTrigger;
                [].forEach.call(inputsByShow, function (block) {
                    const blockValueArray = block.dataset.showBlock.split(", ");

                    if (blockValueArray.includes(valueOption)) {
                        block.classList.remove("is-hide");
                    }
                });
            }
            if (trigger.dataset.hideBlockTrigger) {
                const valueOptionArr =
                    trigger.dataset.hideBlockTrigger.split(",");
                [].forEach.call(inputsByShow, function (block) {
                    const blockValueArray = block.dataset.showBlock.split(", ");

                    [].forEach.call(valueOptionArr, function (valueSelect) {
                        if (blockValueArray.includes(valueSelect)) {
                            block.classList.add("is-hide");
                        }
                    });
                });
            }
        }
    });
}
setVisibleInput();
window.setVisibleInput = setVisibleInput;

// скрывать / показывать поля формы в зависимости от выбранного option
window.addEventListener("load", function () {
    (function () {
        let selectedElements = [];
        const selects = document.querySelectorAll(".js-set-status-container");
        const inputsByShowAll = document.querySelectorAll(
            '[data-show-input-item="all"]'
        );
        if (selects.length > 0) {
            [].forEach.call(selects, function (select) {
                const dropdow = select.querySelector(".dropdown-menu.inner");
                const messageContainer = select.querySelector(
                    ".js-application-message"
                );

                if (dropdow)
                    dropdow.addEventListener("click", function (e) {
                        const target = e.target;
                        if (!target.classList.contains("cb-input__check")) {
                            select.querySelector(".selectpicker").click();
                        }
                        const parentElem = e.target.closest("li");
                        if (parentElem.classList.contains("selected")) {
                            const checkbox = parentElem.querySelector(
                                ".js-cb-input__checkbox"
                            );
                            if (checkbox) {
                                checkbox.checked = true;
                            }
                            selectedElements.push(parentElem);
                        } else {
                            selectedElements = selectedElements.filter(
                                (el) => el !== parentElem
                            );
                            const checkbox = parentElem.querySelector(
                                ".js-cb-input__checkbox"
                            );
                            if (checkbox) {
                                checkbox.checked = false;
                            }
                        }
                        changeItems(e.target);
                        if (messageContainer)
                            messageContainer.classList.add("is-show");

                        if (selectedElements.length > 1) {
                            select.classList.add("is-multiple-elements");
                        } else {
                            select.classList.remove("is-multiple-elements");
                        }
                    });

                function changeItems(trigger) {
                    if (trigger.dataset.showInputTrigger) {
                        const valueOption = trigger.dataset.showInputTrigger;
                        if (!valueOption) return;
                        const valueOptionArray = valueOption.split(", ");

                        // если есть атрибут data-show-input-type, от показываем только эти блоки
                        let inputsByShow;
                        if (trigger.dataset.showInputType) {
                            inputsByShow = document.querySelectorAll(
                                `[data-show-input-type="${trigger.dataset.showInputType}"]`
                            );
                        } else {
                            inputsByShow = document.querySelectorAll(
                                "[data-show-input-item]:not([data-show-input-type])"
                            );
                        }

                        [].forEach.call(inputsByShow, function (block) {
                            const blockValue = block.dataset.showInputItem;
                            if (!blockValue) return;
                            const blockValueArray = blockValue.split(", ");
                            let countEquales = 0;

                            blockValueArray.forEach(function (item) {
                                if (valueOptionArray.includes(item)) {
                                    countEquales++;
                                }
                            });

                            if (countEquales > 0) {
                                block.classList.remove("is-hide");
                            } else {
                                block.classList.add("is-hide");
                            }
                        });

                        if (!trigger.dataset.showInputType) {
                            [].forEach.call(inputsByShowAll, function (block) {
                                block.classList.remove("is-hide");
                            });
                        }
                    }
                }
            });
        }
    })();

    function hideAllItems() {
        // Реализация функции скрытия всех элементов
        const allItems = document.querySelectorAll(
            "[data-show-input-item].js-block-form"
        );
        allItems.forEach((item) => {
            item.classList.add("is-hide");
        });
    }

    // показать блоки, если выбрано любое значение
    (function () {
        const selects = document.querySelectorAll(".js-show-container");
        const inputsByShow = document.querySelectorAll(".js-first-hide-block");

        [].forEach.call(selects, function (select) {
            const dropdown = select.querySelector(".dropdown-menu.inner");
            if (dropdown)
                dropdown.addEventListener("click", function (e) {
                    const target = e.target;
                    changeItems(target);
                });

            function changeItems(trigger) {
                if (trigger.classList.contains("js-first-hide-block-trigger")) {
                    [].forEach.call(inputsByShow, function (block) {
                        block.classList.remove("is-hide");
                    });
                    hideAllItems();
                    showTargetInputItem(trigger);
                }
            }

            function showTargetInputItem(trigger) {
                const hideInputsByShowAll = document.querySelectorAll(
                    "[data-show-input-item].js-block-form.is-hide"
                );
                const dataInputTrigger = trigger.getAttribute(
                    "data-show-input-trigger"
                );
                hideInputsByShowAll.forEach((hideInput) => {
                    const input = hideInput.getAttribute(
                        "data-show-input-item"
                    );
                    // Разделяем значения по запятым и пробелам и проверяем наличие триггера
                    if (input.split(", ").includes(dataInputTrigger)) {
                        showInputItems(hideInput);
                    }
                });
            }

            function showInputItems(item) {
                item.classList.remove("is-hide");
            }
        });
    })();
});

// скрывать / показывать поля формы в зависимости от переключателя да/нет
window.addEventListener("load", function () {
    function initSwitches(container = document) {
        const switchTriggerArr = container.querySelectorAll(
            "[data-toggle-switch-inputs]"
        );
        [].forEach.call(switchTriggerArr, function (switchTrigger) {
            if (switchTrigger)
                switchTrigger.addEventListener("click", function (e) {
                    changeItems(e.target);
                });

            function changeItems(trigger) {
                const valueCheckedOption = trigger.dataset.inputsCheckedTarget;
                const valueUncheckedOption =
                    trigger.dataset.inputsUncheckedTarget;
                const parent = trigger.closest(".js-switch-parent") || document;
                const inputsByShow = parent.querySelectorAll(
                    "[data-switch-input]"
                );
                if (trigger.checked) {
                    [].forEach.call(inputsByShow, function (block) {
                        const blockValue = block.dataset.switchInput;
                        if (blockValue == valueCheckedOption) {
                            block.classList.remove("is-hide-switch");
                        } else if (blockValue == valueUncheckedOption) {
                            block.classList.add("is-hide-switch");
                        }
                    });
                } else {
                    [].forEach.call(inputsByShow, function (block) {
                        const blockValue = block.dataset.switchInput;
                        if (blockValue == valueCheckedOption) {
                            block.classList.add("is-hide-switch");
                        } else if (blockValue == valueUncheckedOption) {
                            block.classList.remove("is-hide-switch");
                        }
                    });
                }
            }

            changeItems(switchTrigger);
        });
    }
    initSwitches();
    window.initSwitches = initSwitches;
});

// указать цену при изменения радио кнопки
(function () {
    const selects = document.querySelectorAll(".js-trigger-row-container");
    [].forEach.call(selects, function (select) {
        const trigger = select.querySelector(".js-trigger-row");
        const price = select.querySelector(".js-trigger-row-price");
        const priceContainer = document.querySelector(".js-price-container");
        const priceInput = document.querySelector(
            ".js-trigger-row-price-input"
        );

        trigger.addEventListener("change", function (e) {
            if (trigger.checked && price && priceContainer) {
                const priceValue = price.textContent;
                priceContainer.textContent = priceValue;
            }
        });

        priceInput.addEventListener("change", function (e) {
            if (trigger.checked && price && priceContainer) {
                setTimeout(() => {
                    const priceValue = price.textContent;
                    priceContainer.textContent = priceValue;
                }, 100);
            }
        });
    });
})();

// валидаци пароля
(function () {
    const inputPasswords = document.querySelectorAll(".js-psw-input");

    inputPasswords.forEach((inputPassword) => {
        const parent = inputPassword.closest(".js-psw-parent");
        const container = parent ? parent : document;
        const letter = container.querySelector(".js-message-letter");
        const capital = container.querySelector(".js-message-capital");
        const number = container.querySelector(".js-message-number");
        const length = container.querySelector(".js-message-length");
        const symbol = container.querySelector(".js-message-symbol");
        const messageDrop = container.querySelector(".js-message-drop");

        // Когда пользователь нажимает на поле пароль, появляется окно сообщения
        inputPassword.addEventListener("focus", function () {
            if (messageDrop) messageDrop.classList.add("show");
        });

        // Когда пользователь щелкает за пределами поля пароля, скройте окно сообщения
        inputPassword.addEventListener("blur", function () {
            if (messageDrop) messageDrop.classList.remove("show");
        });

        // Когда пользователь начинает вводить что-то в поле пароля
        inputPassword.addEventListener("input", function () {
            // проверка букв
            if (letter) {
                const lowerCaseLetters = /[a-z]/g;
                if (inputPassword.value.match(lowerCaseLetters)) {
                    letter.classList.remove("status-error");
                    letter.classList.add("status-primary");
                } else {
                    letter.classList.remove("status-primary");
                    letter.classList.add("status-error");
                }
            }

            if (capital) {
                // Проверка заглавных букв
                const upperCaseLetters = /[A-Z]/g;
                if (inputPassword.value.match(upperCaseLetters)) {
                    capital.classList.remove("status-error");
                    capital.classList.add("status-primary");
                } else {
                    capital.classList.remove("status-primary");
                    capital.classList.add("status-error");
                }
            }

            if (number) {
                // Проверка чисел
                const numbers = /[0-9]/g;
                if (inputPassword.value.match(numbers)) {
                    number.classList.remove("status-error");
                    number.classList.add("status-primary");
                } else {
                    number.classList.remove("status-primary");
                    number.classList.add("status-error");
                }
            }

            if (symbol) {
                // Проверка спецсимволов
                const symbols = /[!@#$%^&*()_+.]/g;
                if (inputPassword.value.match(symbols)) {
                    symbol.classList.remove("status-error");
                    symbol.classList.add("status-primary");
                } else {
                    symbol.classList.remove("status-primary");
                    symbol.classList.add("status-error");
                }
            }

            if (length) {
                // Проверка длины
                if (inputPassword.value.length >= 8) {
                    length.classList.remove("status-error");
                    length.classList.add("status-primary");
                } else {
                    length.classList.remove("status-primary");
                    length.classList.add("status-error");
                }
            }

            setTimeout(() => {
                // вывод сообщения с ошибкой при вводу
                if (
                    (letter && letter.classList.contains("status-error")) ||
                    (capital && capital.classList.contains("status-error")) ||
                    (number && number.classList.contains("status-error")) ||
                    (length && length.classList.contains("status-error")) ||
                    (symbol && symbol.classList.contains("status-error"))
                ) {
                    inputPassword.classList.add("is-error");
                } else {
                    inputPassword.classList.remove("is-error");
                }
            }, 200);
        });
    });
})();

// совпадение повторного пароля с введенным
(function () {
    const inputPassword = document.querySelector(".js-psw-input");
    const inputPasswordRepeated = document.querySelector(".js-psw-repeat");

    if (inputPasswordRepeated) {
        inputPasswordRepeated.addEventListener("change", function () {
            setTimeout(() => {
                if (inputPassword.value !== inputPasswordRepeated.value) {
                    inputPasswordRepeated.classList.add("is-error");
                } else {
                    inputPasswordRepeated.classList.remove("is-error");
                }
            }, 500);
        });
    }
})();

// выбираться все чекбоксы, при клике на "все"
(function () {
    const allCheckboxesTriggers = document.querySelectorAll(
        ".js-checked-all-checkboxes-trigger"
    );
    const allCheckboxes = document.querySelectorAll(
        ".js-checked-all-checkboxes-input"
    );

    [].forEach.call(allCheckboxesTriggers, function (elCheckedAll) {
        elCheckedAll.addEventListener("change", function () {
            if (elCheckedAll.checked) {
                const parent = elCheckedAll.closest(
                    ".js-checked-all-checkboxes-container"
                );
                if (parent) {
                    const checkboxes = parent.querySelectorAll(
                        ".js-checked-all-checkboxes-input"
                    );

                    [].forEach.call(checkboxes, function (el) {
                        el.checked = true;
                    });
                }
            }
        });
    });

    [].forEach.call(allCheckboxes, function (checkbox) {
        checkbox.addEventListener("change", function () {
            if (!checkbox.checked) {
                const parent = checkbox.closest(
                    ".js-checked-all-checkboxes-container"
                );
                if (parent) {
                    const triggerAll = parent.querySelector(
                        ".js-checked-all-checkboxes-trigger"
                    );

                    if (triggerAll) {
                        triggerAll.checked = false;
                    }
                }
            }
        });
    });
})();

// пользовательское соглашение
(function () {
    const header = document.querySelector(".js-header");
    const policy = document.querySelector(".js-policy");
    const policyNext = document.querySelector(".js-policy-next");
    const policyEnd = document.querySelector(".js-policy-end");
    const policyChecked = document.querySelector(".js-policy-checked");
    const stickyBlock = document.querySelector(".js-sticky-block");

    if (!policy && !policyNext && !policyEnd) return;
    let documentHeight = document.documentElement.clientHeight;
    let targetPosition;
    let windowPosition;

    if (!policy.classList.contains("is-checked")) {
        if (header) header.classList.add("is-disabled");
    }

    function setPolicyHandler() {
        targetPosition =
            window.pageYOffset + policy.getBoundingClientRect().bottom;
        windowPosition = window.pageYOffset + documentHeight;
        if (targetPosition <= windowPosition) {
            if (stickyBlock) stickyBlock.classList.add("is-absolute");
            if (!policy.classList.contains("is-checked")) {
                policyNext.style.display = "none";
                policyEnd.style.display = "flex";
            }
        } else {
            if (stickyBlock) stickyBlock.classList.remove("is-absolute");
            if (!policy.classList.contains("is-checked")) {
                policyNext.style.display = "flex";
                policyEnd.style.display = "none";
            }
        }
    }
    setPolicyHandler();

    policyNext.addEventListener("click", function () {
        window.scroll({
            top:
                policy.getBoundingClientRect().height +
                policy.offsetTop -
                documentHeight +
                10,
            behavior: "smooth",
        });
    });

    policyEnd.addEventListener("click", function (e) {
        e.preventDefault();
        policyEnd.style.display = "none";
        policyChecked.style.display = "";
        policy.classList.add("is-checked");
        if (header) header.classList.remove("is-disabled");

        const date = new Date();
        cookie.set("COOKIES_USAGE_ACCEPTED", date.toLocaleString(), {
            expires: 365,
            path: "/",
        });

        policyChecked.textContent = "Ознакомлен " + date.toLocaleString();
        const cityConfirmBlock = document.getElementById("dropCityPicker");
        if (cityConfirmBlock) cityConfirmBlock.classList.remove("is-open");
        var cityConfirmBlockOverlay = cityConfirmBlock.querySelector(
            ".js-city-picker-overlay"
        );
        var cityConfirmBlockCloseBtn = cityConfirmBlock.querySelector(
            ".js-city-popup-close"
        );
        if (cityConfirmBlockOverlay)
            cityConfirmBlockOverlay.classList.add("is-mobile");
        if (cityConfirmBlockCloseBtn)
            cityConfirmBlockCloseBtn.style.display = "inline-flex";
    });

    document.addEventListener("scroll", setPolicyHandler);
    window.addEventListener("resize", function () {
        documentHeight = document.documentElement.clientHeight;
    });
})();

// выбираться все чекбоксы, при клике на "все"
(function () {
    const favoritesCardTrigger = document.querySelectorAll(
        ".js-favorites-card-trigger"
    );

    [].forEach.call(favoritesCardTrigger, function (btn) {
        btn.addEventListener("click", function () {
            const card = btn.closest(".js-favorites-card");

            if (card && card.classList.contains("is-favorite")) {
                card.classList.remove("is-favorite");
            } else if (card && !card.classList.contains("is-favorite")) {
                const container = btn.closest(".js-favorites-cards-container");
                if (container) {
                    const favoritesCards =
                        container.querySelectorAll(".js-favorites-card");
                    [].forEach.call(favoritesCards, function (item) {
                        item.classList.remove("is-favorite");
                    });
                }

                card.classList.add("is-favorite");
            }
        });
    });
})();

//
(function () {
    const countPays = document.querySelectorAll(".js-count-pay");

    [].forEach.call(countPays, function (input) {
        input.addEventListener("input", function () {
            if (input.value.length > 1) {
                const form = input.closest(".js-form");
                if (form) {
                    const btnSubmit = form.querySelector(".js-form-payment");
                    if (btnSubmit) btnSubmit.classList.remove("is-disable");
                }
            }
        });
    });
})();

// разделить число на тысячные
function prettify(num) {
    const n = num.toString();
    return n.replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ");
}

// редактирование итоговой суммы в форме оплаты
(function () {
    const amountEditContainers = Array.from(
        document.querySelectorAll(".js-edit-payment-amount-container")
    );
    if (!amountEditContainers) return;

    [].forEach.call(amountEditContainers, function (container) {
        const trigger = container.querySelector(".js-edit-payment-amount");
        const input = container.querySelector(".js-edit-payment-amount-input");
        const clearInputTrigger = container.querySelector(
            ".js-clear-payment-amount"
        );
        const inputBlock = container.querySelector(
            ".js-block-edit-payment-amount"
        );
        const checkAmount = container.querySelector(
            ".js-check-edit-payment-amount"
        );
        const buttonPayment = container.querySelector(
            ".js-button-payment-amount"
        );
        const countInner = container.querySelector(
            ".js-payment-amount-count-block"
        );
        if (!input || !trigger) return;

        const inputValue = prettify(input.value);

        trigger.addEventListener("click", editText);
        if (clearInputTrigger)
            clearInputTrigger.addEventListener("click", clearInput);
        if (checkAmount) checkAmount.addEventListener("click", changeAmount);

        function editText(e) {
            e.preventDefault();

            trigger.style.display = "none";
            if (inputBlock) inputBlock.style.display = "block";
            if (checkAmount) checkAmount.style.display = "block";
            if (buttonPayment) buttonPayment.style.display = "none";
            input.focus();
            input.selectionStart = input.value.length;

            setTimeout(function () {
                document.addEventListener("click", checkForClose);
            }, 100);
        }

        function clearInput(e) {
            e.preventDefault();
            input.value = "";
            input.focus();
        }

        function closeEditEmount() {
            if (input.value == "") {
                input.value = inputValue;
                if (countInner) countInner.textContent = inputValue;
            } else {
                if (countInner) countInner.textContent = prettify(input.value);
            }
            trigger.style.display = "block";
            if (inputBlock) inputBlock.style.display = "none";
            if (checkAmount) checkAmount.style.display = "none";
            if (buttonPayment) buttonPayment.style.display = "block";

            document.removeEventListener("click", checkForClose);
        }

        function changeAmount(e) {
            e.preventDefault();
            closeEditEmount();
        }

        function checkForClose(e) {
            if (
                !e.target.closest(".js-check-edit-payment-amount") &&
                !e.target.closest(".js-block-edit-payment-amount")
            ) {
                closeEditEmount();
            }
        }
    });
})();

// выпадающий список с картами
(function () {
    const cardsOptions = document.querySelectorAll(".js-cards-option");
    const cardsToogles = document.querySelectorAll(".js-cards-toggle");
    if (!cardsOptions || !cardsToogles) return;

    [].forEach.call(cardsOptions, function (optionTrigger) {
        optionTrigger.addEventListener("click", function (e) {
            e.preventDefault();
            if (optionTrigger.classList.contains("js-cards-link")) {
                window.location = optionTrigger.href;
            } else {
                const container = optionTrigger.closest(".js-cards-container");
                if (!container) return;

                const toggler = container.querySelector(".js-cards-toggle");
                if (toggler) {
                    toggler.innerHTML = optionTrigger.innerHTML;
                    console.dir(optionTrigger);
                }
            }
        });
    });
})();

// расчитать высоту ячеек для блока со скролом
function setCellHeight(container = document) {
    const heightContainers = container.querySelectorAll(
        ".js-row-hight-container"
    );
    if (!heightContainers) return;

    [].forEach.call(heightContainers, function (container) {
        if (!container.closest(".accordion-body.in")) return;
        const rows = container.querySelectorAll(".js-row-hight");
        const cells = container.querySelectorAll(".js-row-hight-cell");

        if (window.matchMedia("(max-width: 1099px)").matches) {
            cells.forEach((cell, i) => {
                if (rows[i]) {
                    cell.style.height = `${rows[i].clientHeight}px`;
                }
            });
        }
    });
}
setCellHeight();

// прокручивать блок со скролом в конец при открытии страницы
function setScrollPositionEnd(container = document) {
    const scrollContainers = container.querySelectorAll(".js-scroll-container");
    if (!scrollContainers) return;

    [].forEach.call(scrollContainers, function (container) {
        if (!container.closest(".accordion-body.in")) return;

        if (window.matchMedia("(max-width: 1099px)").matches) {
            container.scrollLeft = container.scrollWidth;
        }
    });
}
setScrollPositionEnd();

// блокировать другие инпуты, если выбраны Все
(function () {
    const checkboxAllValueArr = document.querySelectorAll(
        ".js-all-reports-trigger"
    );
    if (!checkboxAllValueArr) return;

    [].forEach.call(checkboxAllValueArr, function (el) {
        el.addEventListener("click", function () {
            const parent = el.closest(".js-all-reports-inner");
            if (!parent) return;
            const allCheckboxes = parent.querySelectorAll(
                'input[type="checkbox"]:not(.js-all-reports-trigger)'
            );
            [].forEach.call(allCheckboxes, function (checkbox) {
                if (el.checked) {
                    checkbox.disabled = true;
                    if (checkbox.checked) {
                        checkbox.checked = false;
                        let event = new Event("change");
                        checkbox.dispatchEvent(event);
                    }
                } else {
                    checkbox.disabled = false;
                }
            });
        });
    });
})();

// показывать или скрывать options в зависимости от чекбокса
document.addEventListener("DOMContentLoaded", function () {
    (function () {
        const hiddenOptionTriggers = document.querySelectorAll(
            ".js-hidden-options-trigger"
        );
        if (!hiddenOptionTriggers) return;

        [].forEach.call(hiddenOptionTriggers, function (el) {
            const parent = el.closest(".js-hidden-options-container");
            if (!parent) return;

            function setHiddenOptions() {
                const hiddenOptions = parent.querySelectorAll(
                    "[data-hidden-option]"
                );
                [].forEach.call(hiddenOptions, function (option) {
                    if (el.checked) {
                        option.closest("a").style.display = "";
                    } else {
                        option.closest("a").style.display = "none";
                    }
                });
            }

            setTimeout(() => {
                setHiddenOptions();
            }, 500);

            el.addEventListener("change", setHiddenOptions);
        });
    })();
});

// вернуться на предыдущую страницу
(function () {
    const backPageLinks = document.querySelectorAll(".js-page-back");
    if (!backPageLinks) return;

    [].forEach.call(backPageLinks, function (el) {
        el.addEventListener("click", function (e) {
            e.preventDefault();

            window.history.back();
        });
    });
})();

// сообщить об ошибке при клике на ctrl+enter
(function () {
    document.addEventListener("keypress", function (event) {
        if (
            event.srcElement.tagName != "TEXTAREA" &&
            event.srcElement.tagName != "INPUT"
        ) {
            if (event.keyCode == 10) {
                showModal("modalError");

                // вставляем выделенный текст
                const selectedText = window.getSelection().toString();
                const errorInput = document.querySelector(".js-error-input");
                if (errorInput && selectedText.length > 0) {
                    errorInput.value = selectedText;
                }
            }
        }
    });
})();

// сообщить об ошибке на страницу 404
(function () {
    const errorContainer = document.querySelector(".js-error-container");
    if (!errorContainer) return;
    const containerTrigger = errorContainer.querySelector(
        ".js-container-trigger"
    );
    const formTrigger = errorContainer.querySelector(".js-form-trigger");

    $("#errorInput").on("open", function () {
        if (containerTrigger) containerTrigger.style.display = "none";
        if (formTrigger) formTrigger.style.display = "";
    });
})();

// в зависимости от хештега выбрать тему, option
(function () {
    const hashtagQuestions = document.querySelectorAll(".js-hashtag-question");
    if (!hashtagQuestions.length) return;

    [].forEach.call(hashtagQuestions, function (tag) {
        tag.addEventListener("click", function (e) {
            e.preventDefault();

            const container = tag.closest(".js-hashtag-container");
            const tagValue = tag.dataset.value;

            if (!container || !tagValue) return;
            const select = container.querySelector(".js-questions-select");

            if (select && select.closest(".js-set-status-container")) {
                const theme = select.querySelector(
                    `[data-show-input-trigger="${tagValue}"]`
                );
                theme.click();
            }
        });
    });
})();

// триггер таймера
(function () {
    const arrTriggerContainer = document.querySelectorAll(
        ".js-trigger-timer-container"
    );
    [].forEach.call(arrTriggerContainer, function (container) {
        const triggers = container.querySelectorAll(".js-trigger-timer");
        const inner = container.querySelector(".js-trigger-timer-shows-inner");
        const timer = container.querySelector(".js-timer");
        [].forEach.call(triggers, function (trigger) {
            trigger.addEventListener("click", function (e) {
                e.preventDefault();
                playTimer(timer);
                if (inner) inner.style.display = "";
                trigger.remove();
            });
        });

        if (timer && timer.classList.contains("is-show")) {
            playTimer(timer);
        }
    });
})();

// показывать или скрывать блок при клике
(function () {
    const arrBlocksContainer = document.querySelectorAll(
        ".js-show-blocks-container"
    );
    [].forEach.call(arrBlocksContainer, function (container) {
        const triggers = container.querySelectorAll(".js-show-block-trigger");
        const shownBlock = container.querySelector(".js-shown-block");
        const hiddenBlock = container.querySelector(".js-hidden-block");

        function onTrigger() {
            if (shownBlock) shownBlock.style.display = "";
            if (hiddenBlock) hiddenBlock.style.display = "none";

            if (
                shownBlock &&
                shownBlock.classList.contains("js-disabled-button")
            ) {
                setTimeout(() => {
                    shownBlock.classList.remove("is-disable");
                }, 100);
            }
        }

        [].forEach.call(triggers, function (trigger) {
            trigger.addEventListener("click", onTrigger);
            trigger.addEventListener("change", onTrigger);
        });
    });
})();

// когда можно заполнить только одно поле
(function () {
    const arrInputsContainer = document.querySelectorAll(
        ".js-only-one-input-container"
    );

    [].forEach.call(arrInputsContainer, function (container) {
        const input1 = container.querySelector(".js-only-one-input--one");
        const input2 = container.querySelector(".js-only-one-input--two");

        if (!input1 || !input2) return;

        input1.addEventListener("input", function () {
            if (input1.value) {
                input2.disabled = true;
            } else {
                input2.disabled = false;
            }
        });

        input2.addEventListener("input", function () {
            if (input2.value) {
                input1.disabled = true;
            } else {
                input1.disabled = false;
            }
        });
    });
})();

// capcha
(function () {
    const arrCapchaContainer = document.querySelectorAll(
        ".js-capcha-container"
    );

    [].forEach.call(arrCapchaContainer, function (container) {
        const showCapchaTriggers = container.querySelectorAll(
            ".js-capcha-show-trigger"
        );
        const showCapchaContainer = container.querySelector(
            ".js-capcha-show-container"
        );
        const input = container.querySelector(".js-capcha-input");
        /**
         * Кнопка "ОК", которая появляется после успешного ввода капчи
         */
        const nextTrigger = container.querySelector(".js-capcha-next-trigger");
        const image = container.querySelector(".js-capcha-image");
        const contentDisabled = container.querySelectorAll(
            ".js-capcha-content-disabled"
        );
        /**
         * Коллекция html-элементов, которые появятся после успешно введенной капчи
         */
        const hiddenBlocks = container.querySelectorAll(
            ".js-capcha-hidden-block"
        );
        const hiddenBlockTriggers = container.querySelectorAll(
            ".js-capcha-show-hidden-blocks-trigger"
        );
        const shownBlocks = container.querySelectorAll(
            ".js-capcha-show-hidden-blocks"
        );
        const hiddenEnteringBlocks = container.querySelectorAll(
            ".js-capcha-hidden-block-entering"
        );
        /**
         * Кнопка, которая убирает кнопку "Отменить"
         */
        const hideCancelBtn = container.querySelector(
            ".js-precaptcha-hide-cancel-btn"
        );
        /**
         * Кнопка "Отменить"
         */
        const cancelBtn = container.querySelector(".js-precaptcha-cancel-btn");

        if (input) {
            input.addEventListener("input", function () {
                if (input.value.length === 7) {
                    input.classList.remove("is-error");
                    if (nextTrigger) nextTrigger.style.display = "";
                    if (image && !image.classList.contains("not-hide"))
                        image.style.display = "none";

                    contentDisabled.forEach((item) => {
                        if (
                            !item.classList.contains("undisabled-after-review")
                        ) {
                            item.classList.remove("is-disable");
                        }
                    });
                } else {
                    if (nextTrigger) nextTrigger.style.display = "none";
                    if (image) image.style.display = "";

                    contentDisabled.forEach((item) => {
                        item.classList.add("is-disable");
                    });
                }
            });

            input.addEventListener("change", function () {
                if (input.value.length !== 7) {
                    input.classList.add("is-error");
                }
            });
        }

        if (hideCancelBtn) {
            hideCancelBtn.addEventListener("click", () => {
                if (cancelBtn) {
                    cancelBtn.style.display = "none";
                }
            });
        }

        if (nextTrigger) {
            nextTrigger.addEventListener("click", () => {
                contentDisabled.forEach((item) => {
                    item.classList.remove("is-disable");
                });
                if (!nextTrigger.classList.contains("show-code-trigger")) {
                    if (input) {
                        input.value = "";
                    }

                    hiddenBlocks.forEach((item) => {
                        item.style.display = "";
                    });
                    if (showCapchaContainer) {
                        showCapchaContainer.style.display = "none";
                    }

                    hiddenEnteringBlocks.forEach((item) => {
                        if (
                            !item.classList.contains("js-capcha-show-trigger")
                        ) {
                            item.style.display = "";
                        }
                    });

                    if (nextTrigger) nextTrigger.style.display = "none";
                    if (image) image.style.display = "";
                }
            });
        }

        if (showCapchaContainer) {
            showCapchaTriggers.forEach((trigger) => {
                trigger.addEventListener("click", () => {
                    showCapchaContainer.style.display = "";

                    hiddenEnteringBlocks.forEach((item) => {
                        item.style.display = "none";
                    });

                    showCapchaTriggers.forEach(
                        (trigger) => (trigger.style.display = "none")
                    );
                });
            });
        }

        hiddenBlockTriggers.forEach((trigger) => {
            trigger.addEventListener("click", () => {
                hiddenBlockTriggers.forEach(
                    (trigger) => (trigger.style.display = "none")
                );
                shownBlocks.forEach((trigger) => (trigger.style.display = ""));
            });
        });
    });
})();

// code validate
(function () {
    /**
     * Кнопки отправки введенного кода
     */
    const arrCodeSubmit = document.querySelectorAll(".js-code-submit");

    [].forEach.call(arrCodeSubmit, function (trigger) {
        const container = trigger.closest(".js-code-container");
        if (!container) return;

        trigger.addEventListener("click", () => {
            const codeInners = container.querySelectorAll(".js-code-inner");
            /**
             * Поле ввода кода
             */
            const codeInput = container.querySelector(".js-code-input");
            const statusValue = container.querySelector(".js-status-value");
            const successMessageBlock = container.querySelector(
                ".js-success-message"
            );
            const errorMessageBlock =
                container.querySelector(".js-error-message");
            const hiddenBlockArr = container.querySelectorAll(
                ".js-capcha-hidden-block"
            );
            const shownBlockArr = container.querySelectorAll(
                ".js-code-shown-block"
            );
            const capchaBlock = container.querySelectorAll(
                ".js-capcha-show-container"
            );
            const capchaHiddenBlock = container.querySelectorAll(
                ".js-capcha-hidden-block-entering"
            );
            /**
             * Исходный инпут, который валидируется
             */
            const input = container.querySelector(".js-base-input");

            const isValid = codeInput.value.length > 0; // TODO: проверка на бэке

            if (isValid) {
                if (codeInput) {
                    codeInput.classList.remove("is-error");
                    codeInput.value = "";
                }

                hiddenBlockArr.forEach((item) => {
                    item.style.display = "none";
                });
                codeInners.forEach((item) => {
                    item.style.display = "none";
                });
                shownBlockArr.forEach((item) => {
                    item.style.display = "";
                });
                if (successMessageBlock) successMessageBlock.style.display = "";
                if (errorMessageBlock) errorMessageBlock.style.display = "none";
                if (statusValue) statusValue.classList.add("is-success");

                if (trigger.classList.contains("js-show-capcha-again")) {
                    capchaBlock.forEach((el) => {
                        el.style.display = "";
                    });
                    capchaHiddenBlock.forEach((el) => {
                        el.style.display = "none";
                    });
                }

                if (trigger.classList.contains("js-make-input-readonly")) {
                    if (container) {
                        const confirmBtn = container.querySelector(
                            ".js-input-confirm-btn"
                        );

                        if (input) {
                            input.readOnly = true;
                        }

                        if (confirmBtn) {
                            confirmBtn.style.display = "none";
                        }
                    }
                }
            } else {
                if (codeInput) {
                    codeInput.classList.add("is-error");
                }
            }
        });
    });
})();

// email validate
(function () {
    const validEmail = document.querySelectorAll(".js-valid-email");

    [].forEach.call(validEmail, function (input) {
        input.addEventListener("change", () => {
            if (emailPattern.test(input.value)) {
                if (input.value.length < 51) {
                    input.classList.add("is-success");
                } else {
                    input.classList.remove("is-success");
                }
            } else {
                input.classList.remove("is-success");
            }
        });
    });
})();

// разблокировать кнопку при валидации
(function () {
    const validInputArr = document.querySelectorAll(".js-valid-input");

    [].forEach.call(validInputArr, function (input) {
        const container = input.closest(".js-valid-input-container");
        if (!container) return;

        const disabledBlocks = container.querySelectorAll(
            ".js-valid-input-disabled"
        );

        const setStatus = () => {
            setTimeout(() => {
                if (input.classList.contains("is-success")) {
                    disabledBlocks.forEach((item) => {
                        item.classList.remove("is-disable");
                    });
                } else {
                    disabledBlocks.forEach((item) => {
                        item.classList.add("is-disable");
                    });
                }
            }, 200);
        };

        input.addEventListener("change", setStatus);
        input.addEventListener("blur", setStatus);
    });
})();

// ЛС шаги
(function () {
    const stepTriggerArr = document.querySelectorAll(".js-step-trigger");

    [].forEach.call(stepTriggerArr, function (trigger) {
        const container = trigger.closest(".js-tab-content");
        if (!container) return;

        trigger.addEventListener("click", () => {
            const stepId = container.id;
            const nextStepHref = trigger.getAttribute("href");
            const stepLink = document.querySelector(
                '.js-step-link[href="#' + stepId + '"]'
            );
            const stepNextLink = document.querySelector(
                '.js-step-link[href="' + nextStepHref + '"]'
            );
            if (stepLink) {
                stepLink.classList.add("password-recovery__steps-item--active");
            }
            if (stepNextLink) {
                stepNextLink.classList.add(
                    "password-recovery__steps-item--active"
                );
            }
        });
    });
})();

(function () {
    const $forms = $("form.js-form-application");

    $forms.each(function (index, form) {
        const saveBtn = form.querySelector(".js-form-application-save-btn");
        const deleteBtn = form.querySelector(".js-form-application-delete-btn");
        const withdrawBtn = form.querySelector(
            ".js-form-application-withdraw-btn"
        );
        const submitBtn = form.querySelector(".js-form-application-submit-btn");

        saveBtn?.addEventListener("click", () => {
            console.log("Черновик сохранен");
            saveBtn.style.display = "none";

            if (deleteBtn) {
                deleteBtn.style.display = "";
            }
        });

        deleteBtn?.addEventListener("click", () => {
            console.log("Черновик удален");
            deleteBtn.style.display = "none";

            if (saveBtn) {
                saveBtn.style.display = "";
            }
        });

        withdrawBtn?.addEventListener("click", () => {
            console.log("Отозвано");
        });

        $(form).on("ajax.success", function (event, response) {
            if (saveBtn) {
                saveBtn.style.display = "none";
            }

            if (withdrawBtn) {
                withdrawBtn.style.display = "";
            }

            if (submitBtn) {
                submitBtn.innerHTML = response.message;
            }
        });
    });
})();

// вычисление кол-во строк в блоке с текстом
(function () {
    const enityInfoWrapper = document.querySelectorAll(
        ".js-entity-info__text-wrapper"
    );
    if (enityInfoWrapper.length > 0) {
        enityInfoWrapper.forEach((wrapper) => {
            const block = wrapper.querySelector(".js-entity-info__text");
            const readMoreBtn = wrapper.querySelector(".js-read-more");
            const lineHeight = parseFloat(getComputedStyle(block).lineHeight);
            const blockHeight = block.clientHeight;
            const lines = Math.floor(blockHeight / lineHeight);
            if (lines >= 4 && readMoreBtn) {
                readMoreBtn.classList.remove("visually-hidden");
            } else {
                if (!readMoreBtn.classList.contains("visually-hidden")) {
                    readMoreBtn.classList.add("visually-hidden");
                }
            }
        });
    }
})();

(function () {
    const listOfHitsPage = document.querySelector(".js-list-of-hits__page");
    if (listOfHitsPage) {
        const originalElements = listOfHitsPage.querySelectorAll(
            ".js-request-more__bottom-inner-desktop"
        );
        const mobileTargets = listOfHitsPage.querySelectorAll(
            ".js-request-more__bottom-inner-mobile"
        );

        function moveElements() {
            if (window.matchMedia("(max-width: 650px)").matches) {
                // Перемещаем каждый оригинальный элемент в соответствующий мобильный контейнер
                originalElements.forEach((originalElement, index) => {
                    const mobileTarget = mobileTargets[index];
                    if (
                        mobileTarget &&
                        !mobileTarget.contains(originalElement)
                    ) {
                        mobileTarget.appendChild(originalElement);
                    }
                });
            } else {
                // Возвращаем каждый элемент на исходное место при ширине экрана больше 650px
                originalElements.forEach((originalElement) => {
                    const originalParent = originalElement.closest(
                        ".original-parent-selector"
                    ); // Замените на актуальный селектор исходного родителя
                    if (
                        originalParent &&
                        !originalParent.contains(originalElement)
                    ) {
                        originalParent.appendChild(originalElement);
                    }
                });
            }
        }

        // Выполнение перемещения при загрузке и изменении размера экрана
        window.addEventListener("resize", moveElements);
        document.addEventListener("DOMContentLoaded", moveElements);
    }
})();
